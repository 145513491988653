import { TransactionStatistic } from 'models/transaction';
import { API } from 'utils/api/api-route';
import ApiUtils from 'utils/api/api.utils';

const TransactionService = {
  getTransactionStatistic: async () => {
    const res: TransactionStatistic[] = await ApiUtils.fetch(API.getTransactionStatistic);

    // シルフィ→フレイヤの順番になるようにデータを並び替え
    return res.sort(function (a, b) {
      return a.characterId - b.characterId; // CharacterIdの昇順
    });
  },
};

export default TransactionService;
