import { Card } from 'antd';
import React from 'react';
import styles from '../styles.module.scss';
import { useTranslation } from 'react-i18next';
import { DataRes2nd } from 'models/2nd-nft';
import moment from 'moment';
import { STATUS_NFT_2ND } from 'constants/common';
import DefaultThumnail from 'assets/images/default-thumbnail.jpeg';
import { changeStatusNft2ndMutiLanguage } from 'utils';

interface Props {
  item: DataRes2nd;
  onClickNFT: (item: DataRes2nd) => void;
}

const CardItemNftApproveReject = ({ item, onClickNFT }: Props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const renderImageSrc = () => {
    if (item?.preview2DStatus === 'created' && item?.preview2DObjectKey) {
      return item?.preview2DObjectKey;
    } else {
      return DefaultThumnail;
    }
  };

  return (
    <Card
      className={styles.containerCard}
      onClick={() => onClickNFT(item)}
      cover={<img alt="img" className={styles.imageCoverCard} src={renderImageSrc()} />}
      bodyStyle={{ padding: 20 }}
    >
      <div className={styles.contentCard}>
        <h3>
          <span className={styles.name}>{item?.name}</span>
          <span
            className={styles.status}
            style={{
              color: item?.secondNftStatus === STATUS_NFT_2ND.REJECT ? '#ff7070' : '#4ac86d',
            }}
          >
            {changeStatusNft2ndMutiLanguage(item?.secondNftStatus, lang)}
          </span>
        </h3>
        <b>
          <p>
            {t('MyPage.list2ndRequest.timeApply')}： {moment(item?.createdAt).format('YYYY-MM-DD')}
          </p>
        </b>
        <p>{item?.description}</p>
      </div>
    </Card>
  );
};

export default CardItemNftApproveReject;
