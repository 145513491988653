import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import { LANGUAGE } from 'constants/common';
import { SERVER_API_ENDPOINT } from 'constants/envs';
import {
  AUTHENTICATION_CLIENT_HEADER,
  CUSTOM_LANGUAGE_HEADER,
} from 'constants/header-name.constant';
import { getUpbondToken, getUpbondTokenExpire } from 'utils/auth/auth.utils';
import localStorageHelper, { KeyStorage } from 'utils/localStorage';
import { ResponseCode } from './api.types';

type CustomHeaders = {
  isAuth?: boolean;
};

type HeaderParams = {
  [CUSTOM_LANGUAGE_HEADER]?: string;
  [AUTHENTICATION_CLIENT_HEADER]?: string;
};

const REQ_TIMEOUT = 25 * 1000;
export const __DEV__ = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'develop';

const instance = axios.create({
  baseURL: SERVER_API_ENDPOINT,
  timeout: REQ_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

instance.interceptors.request.use(
  (_config) => requestHandler(_config),
  (error) => {
    if (error.response && error.response.data) {
      const expireTime = getUpbondTokenExpire();
      if (new Date().getTime() - 10000 >= new Date(expireTime).getTime()) {
        return Promise.resolve('');
      }
    }
  }
);

const initHeader: CustomHeaders = { isAuth: true };

export const getHeader = (customHeaders?: CustomHeaders, isUpdateS3 = false): any => {
  const headerParams: HeaderParams = {};
  const initCustomHeader = { ...initHeader, ...customHeaders };

  if (initCustomHeader?.isAuth) {
    const authToken = getUpbondToken();
    if (authToken?.trim()) {
      headerParams[AUTHENTICATION_CLIENT_HEADER] = `${authToken}`;
    }
  }
  headerParams[CUSTOM_LANGUAGE_HEADER] = localStorageHelper.get(KeyStorage.I18n) || LANGUAGE.JAPAN;

  return isUpdateS3 ? customHeaders : headerParams;
};

const requestHandler = (request: AxiosRequestConfig) => {
  return request;
};

instance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

const errorHandler = (error: AxiosError) => {
  const resError: any = error;

  const config: any = error.config;
  if (resError?.data?.code === ResponseCode.UNAUTHORIZED) {
    // Unauthorized
    config._isRefreshToken = true;
  } else if (
    // resError?.data?.code === ResponseCode.BAD_REQUEST ||
    resError?.response?.data?.code === ResponseCode.NOT_FOUND ||
    resError?.response?.data?.code === ResponseCode.PERMISSION
  ) {
    // Show notifications
  }

  if (__DEV__) {
    console.log(`Response API:`, resError?.response?.data);
  }

  if (error.response && error.response.data) {
    const expireTime = getUpbondTokenExpire();
    if (new Date().getTime() - 10000 >= new Date(expireTime).getTime()) {
      return Promise.resolve('');
    }
  }

  return Promise.reject({ ...resError?.response?.data });
};

const successHandler = async (response: AxiosResponse) => {
  if (__DEV__) {
    console.log(`Response API: ${response.config.url}`, response.data);
  }
  const data: any = response.data;
  if (!data || data.status === 'INVALID_TOKEN' || data.code === ResponseCode.UNAUTHORIZED) {
    return;
  }
  return data;
};

async function fetch<ReqType, ResType>(
  url: string,
  params?: ReqType,
  customHeaders?: CustomHeaders,
  responseType?: ResponseType,
  cookie = false
): Promise<ResType> {
  const headers = await getHeader(customHeaders);

  return instance.get(url, {
    withCredentials: cookie,
    params, headers, responseType });
}

async function post<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: CustomHeaders
): Promise<ResType> {
  const headers = await getHeader(customHeaders);
  return instance.post(url, { ...data }, { headers });
}

async function postForm<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: CustomHeaders
): Promise<ResType> {
  const headers = await getHeader(customHeaders);
  return instance.post(url, data, { headers });
}

async function put<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: CustomHeaders
): Promise<ResType> {
  const headers = await getHeader(customHeaders);
  return instance.put(url, { ...data }, { headers });
}

async function postS3<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: CustomHeaders,
  isUpdateS3 = false
): Promise<ResType> {
  const headers = await getHeader(customHeaders, isUpdateS3);
  return instance.put(url, data, { headers });
}

async function patch<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: CustomHeaders
): Promise<ResType> {
  const headers = await getHeader(customHeaders);
  return instance.patch(url, { ...data }, { headers });
}

async function remove<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: CustomHeaders
): Promise<ResType> {
  const headers = await getHeader(customHeaders);
  return instance.delete(url, { data: { ...data }, headers: { ...headers } });
}

const ApiUtils = { fetch, post, put, postForm, remove, patch, postS3 };
export default ApiUtils;
