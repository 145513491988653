import { Modal } from 'antd';
import React from 'react';
import ReactLoading from 'react-loading';

interface Props {
  isOpen: boolean;
}

const GlobalLoading = ({ isOpen }: Props) => {
  return (
    <Modal open={isOpen} closable={false} className="custom-loading-modal" centered footer={null}>
      <ReactLoading type="spinningBubbles" color="#18c4af" />
    </Modal>
  );
};

export default GlobalLoading;
