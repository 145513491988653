import { CopyOutlined, EyeOutlined, HeartFilled, HeartOutlined } from '@ant-design/icons';
import { Card, Tooltip } from 'antd';
import iconPlay from 'assets/images/icons/icon-play.svg';
import { TYPE_1ST } from 'constants/common';
import { NftDataRes, OwnedNft } from 'models/nft';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import BG_MODEL from 'assets/images/default-thumbnail.jpeg';

interface Props {
  item: NftDataRes;
  nftOnwedList: Array<OwnedNft>;
  titleFilter: number;
  onClickBtnCustomNFT: (id: number) => void;
  onClickBtnPlayNFT: (id: number) => void;
  onClickDetailNFT: (item: NftDataRes) => void;
  handleLike: (id: string | number) => void;
}

const CardItemNft = ({
  item,
  nftOnwedList,
  onClickBtnCustomNFT,
  onClickDetailNFT,
  onClickBtnPlayNFT,
  handleLike,
  titleFilter,
}: Props) => {
  const { t } = useTranslation();
  const onImageError = (e) => {
    e.target.src = BG_MODEL;
  };

  return (
    <Card
      className={styles.containerCard}
      cover={
        <img
          onClick={() => onClickDetailNFT(item)}
          alt="img"
          className={styles.imageCoverCard}
          src={item?.previewImg || BG_MODEL}
          onError={onImageError}
        />
      }
      bordered={false}
      bodyStyle={{ padding: 20 }}
    >
      <div className={styles.boxTypeNft}>{item?.is2D ? '2D' : '3D'}</div>
      <div className={styles.boxCountLike} onClick={() => handleLike(item?.nftId)}>
        {item?.isLiked ? (
          <HeartFilled style={{ height: 16, width: 14, color: 'red' }} />
        ) : (
          <HeartOutlined style={{ height: 16, width: 14 }} />
        )}
        <span style={{ marginLeft: 5 }}> {item?.countLike}</span>
      </div>
      <div className={styles.contentCard}>
        <Tooltip placement="topLeft" title={item?.name}>
          <h3
            title={item?.name}
            className={styles.formatText}
            onClick={() => onClickDetailNFT(item)}
          >
            {item?.name}
          </h3>
        </Tooltip>
        <Tooltip placement="topLeft" title={item?.description}>
          <p title={item?.description} className={styles.formatText}>
            {item?.description}
          </p>
        </Tooltip>
        <div className={styles.footerCard}>
          <div
            className={
              item?.firstNftType === TYPE_1ST.Body || (titleFilter === 6 && !item.is2D)
                ? ''
                : styles.action
            }
          >
            <EyeOutlined className={styles.eye} /> {item?.countView || 0}
            <div className={styles.copy}>
              <CopyOutlined />{' '}
              {nftOnwedList?.find((nft) => nft.nftId == item.nftId)?.totalRemainAmount || 0}
            </div>
          </div>
          {titleFilter === 6 && !item.is2D && (
            <button onClick={() => onClickBtnPlayNFT(item.id)} className="btn btn--playNft">
              <img src={iconPlay} alt="Play" style={{ marginRight: 8 }} />
              {t('MyPage.btnPlay')}
            </button>
          )}
          {item?.firstNftType === TYPE_1ST.Body &&
            titleFilter !== 6 &&
            titleFilter !== 7 &&
            Number(item.remainAmount) >= 1 && (
              <button onClick={() => onClickBtnCustomNFT(item.id)} className="btn btn--cardNft">
                {t('MyPage.btnCustomize')}
              </button>
            )}
        </div>
      </div>
    </Card>
  );
};

export default CardItemNft;
