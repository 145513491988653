import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import React, { Children, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import styles from './index.module.scss';

const CAROUSEL_CONFIG = { MAX_VISIBILITY_ITEM_PER_SIDE: 3, PERCENT_ITEM_VISIBLE: 50 };
const TOTAL_ITEM_VISIBLE = CAROUSEL_CONFIG.MAX_VISIBILITY_ITEM_PER_SIDE * 2 - 1;
let debounce: ReturnType<typeof setTimeout>;

type Props = {
  name: string;
  width: string;
  height: string;
};

const Carousel: React.FC<PropsWithChildren<Props>> = ({ name, width, height, children }) => {
  const count = Children.count(children);
  const [active, setActive] = useState(0);

  useEffect(() => {
    const defaultIndex = Math.round(count / 2) - 1 < 0 ? 0 : Math.round(count / 2);

    setActive(
      count < TOTAL_ITEM_VISIBLE
        ? defaultIndex
        : Math.floor(CAROUSEL_CONFIG.MAX_VISIBILITY_ITEM_PER_SIDE / 2) + 1
    );
  }, [count]);

  useEffect(() => {
    const carousel = document.getElementById(name);

    let isDrag = false;
    let clientXStart = 0;

    const isPCEvent = (e: MouseEvent | TouchEvent): e is MouseEvent => {
      return !!(e as MouseEvent).clientX;
    };

    const dragging = (e: MouseEvent | TouchEvent) => {
      if (!isDrag) return;
      e.preventDefault();

      if (debounce) clearTimeout(debounce);

      debounce = setTimeout(() => {
        setActive((pre: any) => {
          const clientX = isPCEvent(e) ? e.clientX : e.touches[0].clientX;
          const activeIndex =
            clientXStart - clientX > 0 ? pre + 1 : clientXStart - clientX < 0 ? pre - 1 : 0;

          if (activeIndex > count - 1 || activeIndex === -1) return pre;

          return activeIndex;
        });
      }, 150);
    };

    const onMouseDown = (e: MouseEvent | TouchEvent) => {
      isDrag = true;

      clientXStart = isPCEvent(e) ? e.clientX : e.touches[0].clientX;
    };

    const onMouseUp = () => {
      isDrag = false;
    };

    carousel?.addEventListener('mousedown', onMouseDown);
    carousel?.addEventListener('mouseup', onMouseUp);
    carousel?.addEventListener('mousemove', dragging);

    carousel?.addEventListener('touchstart', onMouseDown);
    carousel?.addEventListener('touchend', onMouseUp);
    carousel?.addEventListener('touchleave', onMouseUp);
    carousel?.addEventListener('touchmove', dragging);

    return () => {
      carousel?.removeEventListener('mousedown', onMouseDown);
      carousel?.removeEventListener('mouseup', onMouseUp);
      carousel?.removeEventListener('mousemove', dragging);

      carousel?.removeEventListener('touchstart', onMouseDown);
      carousel?.removeEventListener('touchend', onMouseUp);
      carousel?.removeEventListener('touchleave', onMouseUp);
      carousel?.removeEventListener('touchmove', dragging);
    };
  }, [name, count]);

  return (
    <div style={{ position: 'relative' }}>
      {active > 0 && (
        <button
          className={`${styles.nav} ${styles['nav--left']}`}
          onClick={() => setActive((i) => i - 1)}
        >
          <ArrowLeftOutlined />
        </button>
      )}
      <div className={styles['carousel-wrapper']} id={name}>
        <div className={styles.carousel} style={{ width, height }}>
          {Array.isArray(children) &&
            Children.map(children, (child: ReactElement, i: number) => {
              const offset = (active - i) / CAROUSEL_CONFIG.MAX_VISIBILITY_ITEM_PER_SIDE;
              const direction = Math.sign(active - i) * -CAROUSEL_CONFIG.PERCENT_ITEM_VISIBLE + '%';
              const absOffset = Math.abs(offset);

              return (
                <div
                  className={styles['carousel--item']}
                  style={
                    {
                      display:
                        Math.abs(active - i) > CAROUSEL_CONFIG.MAX_VISIBILITY_ITEM_PER_SIDE
                          ? 'none'
                          : 'block',
                      opacity:
                        Math.abs(active - i) >= CAROUSEL_CONFIG.MAX_VISIBILITY_ITEM_PER_SIDE
                          ? '0'
                          : '1',
                      transform: `rotateY(calc(${offset} * 65deg)) scaleY(calc(1 + ${absOffset} * -0.4))
                      translateZ(calc(${absOffset} * -10rem * ${TOTAL_ITEM_VISIBLE})) translateX(${direction})`,
                      cursor: 'pointer',
                    } as any
                  }
                  onClick={() => {
                    setActive(i);
                  }}
                  key={`${name}-item-${i}`}
                >
                  {child}
                </div>
              );
            })}
        </div>
      </div>
      {active < count - 1 && (
        <button
          className={`${styles.nav} ${styles['nav--right']}`}
          onClick={() => setActive((i) => i + 1)}
        >
          <ArrowRightOutlined />
        </button>
      )}
    </div>
  );
};

export default Carousel;
