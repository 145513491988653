import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetCharacterRequestParams, GetPositionRequestParams } from 'models/common';
import MasterServices from 'services/master.service';

export const fetchCharacter = createAsyncThunk(
  'master/fetchCharacter',
  async (params: GetCharacterRequestParams = { limit: 1000, page: 1 }) => {
    const response = await MasterServices.getCharacters(params);
    return response.items;
  }
);
export const fetchBackground = createAsyncThunk('master/fetchBackground', async () => {
  const response = await MasterServices.getBackgrounds();

  return response.items;
});

export const fetchWeaponPart = createAsyncThunk(
  'master/part',
  async (params: GetPositionRequestParams = { limit: 1000, page: 1 }) => {
    const response = await MasterServices.getParts(params);
    return response.items;
  }
);
