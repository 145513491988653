import { Col, notification, Row, Table, Tooltip } from 'antd';
import { DataResTransactionDetail, ItemDetail } from 'models/my-page';
import moment from 'moment';
import { AlignType } from 'rc-table/lib/interface';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'utils';
import { API } from 'utils/api/api-route';
import ApiUtils from 'utils/api/api.utils';
import { shortenName } from 'utils/short-word';
import styles from '../styles.module.scss';
import { LANGUAGE } from 'constants/common';

interface IProps {
  id: number | string | undefined;
}

const TransactionDetail = ({ id }: IProps) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [transactionDetailData, setTransactionDetailData] =
    useState<DataResTransactionDetail | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const getTransactionDetailData = useCallback(async () => {
    setLoading(true);
    try {
      const result: DataResTransactionDetail = await ApiUtils.fetch(
        `${API.detailTransaction}/${id}`
      );
      setTransactionDetailData(result);
    } catch (error) {
      console.error(`Error transaction detail`, JSON.stringify(error));
      notification.error({ message: 'Error', description: t('common.messageWrong'), duration: 4 });
    } finally {
      setLoading(false);
    }

    setLoading(false);
  }, [id, t]);

  useEffect(() => {
    getTransactionDetailData();
  }, [id, getTransactionDetailData]);

  const TableFooter = ({ data }: { data: Array<ItemDetail> }) => {
    const isYen = lang === LANGUAGE.JAPAN;
    const totalAmount =
      Number(isYen ? transactionDetailData?.totalAmount : transactionDetailData?.totalAmountUsd) ||
      0;
    const totalDiscount =
      Number(isYen ? transactionDetailData?.discount : transactionDetailData?.discountUsd) || 0;

    return (
      <div
        className={styles.totalBox}
        style={{ marginRight: lang === LANGUAGE.JAPAN ? '40px' : '25px' }}
      >
        <div
          className={styles.discount}
          style={{
            margin: lang === LANGUAGE.JAPAN ? '0 10px 10px 15px' : '0 10px 10px 0',
          }}
        >
          {totalDiscount > 0 && (
            <>
              <p className={styles.totalText}>{t('Payment.includeDiscount')}</p>
              <p
                className={styles.totalAmount}
                style={{ textAlign: data?.length > 5 ? 'right' : 'center' }}
              >
                {formatNumber(totalDiscount, lang)}
              </p>
            </>
          )}
        </div>
        <div className={styles.price}>
          <p className={styles.totalText}> {t('MyPage.detailTransaction.total')}:</p>
          <p className={styles.totalAmount}>
            {formatNumber(totalAmount, lang)}({t('Payment.includeTax')})
          </p>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'nftId',
      key: 'nftId',
      align: 'center' as AlignType,
    },

    {
      title: t('MyPage.detailTransaction.name'),
      dataIndex: 'nftName',
      key: 'nftName',
      render: (text) => <Tooltip title={text}>{shortenName(text || '', 30) || ''}</Tooltip>,
      align: 'center' as AlignType,
      width: (transactionDetailData?.items?.length ?? 0) > 5 ? 280 : 200,
    },
    {
      title: t('MyPage.detailTransaction.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center' as AlignType,
    },

    {
      title: `${t('MyPage.detailTransaction.amount')}(${t('Payment.includeTax')})`,
      dataIndex: 'price',
      key: 'price',
      align: 'center' as AlignType,
      render: (_, record) => (
        <span>
          {formatNumber(
            lang === LANGUAGE.JAPAN ? Number(record?.price) : Number(record?.priceUsd),
            lang
          )}
        </span>
      ),
    },
    {
      title: `${t('MyPage.detailTransaction.price')}(${t('Payment.includeTax')})`,
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (_, record: ItemDetail) =>
        formatNumber(
          (lang === LANGUAGE.JAPAN ? Number(record?.price) : Number(record?.priceUsd)) *
            record?.quantity,
          lang
        ),
      align: 'center' as AlignType,
    },
  ];

  return (
    <div className="container-modal-transaction">
      <Table
        dataSource={transactionDetailData?.items}
        columns={columns}
        footer={(currentPageData) => <TableFooter data={[...currentPageData]} />}
        pagination={false}
        rowKey="id"
        loading={loading}
        scroll={{ x: 500 }}
      />
      <Row className={styles.boxFooter}>
        <Col xs={7} md={4}>
          <p className={styles.labelModal}>{t('MyPage.detailTransaction.buyDate')}</p>
          <p>{t('MyPage.detailTransaction.status')}</p>
        </Col>
        <Col>
          <p className={styles.info}>
            {moment(transactionDetailData?.purchaseDate).format('YYYY-MM-DD')}
          </p>
          <p className={styles.info}>{transactionDetailData?.displayStatus}</p>
        </Col>
      </Row>
    </div>
  );
};

export default TransactionDetail;
