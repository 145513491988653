import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { paths } from 'routes/routes';

function About() {
  const { t } = useTranslation();

  const ABOUT_DTA = [
    {
      text: 'home.about-1',
      link: paths.whatCanDo,
    },
    {
      text: 'home.about-2',
      link: paths.whatIsDta,
    },
    {
      text: 'home.about-3',
      link: paths.community,
    },
  ];

  return (
    <div className="about-section">
      {ABOUT_DTA.map((item: any, index: number) => (
        <Link key={'about-link-' + index} to={item?.link}>
          <p className="about" key={`abount-item-${index}`}>
            <span className="about__index">{`${index + 1}. `}</span>
            <span className="about__text">{t(item?.text)}</span>
          </p>
        </Link>
      ))}
    </div>
  );
}

export default About;
