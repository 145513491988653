import { Col, Row } from 'antd';
import React from 'react';
import styles from './styles.module.scss';
import TwitterIcon from 'assets/images/icons/twitter.svg';
// import FbIcon from 'assets/images/icons/Facebook.svg';
import DiscordIcon from 'assets/images/icons/Discord.svg';
import { useTranslation } from 'react-i18next';
import { paths } from 'routes/routes';
import pendingBg from 'assets/images/pendingbg.png';
import MegahouseIcon from 'assets/images/icons/megahouse.png';
import UpbondIcon from 'assets/images/icons/upbond.png';
import BandaiIcon from 'assets/images/icons/bandai.png';
import { isMobile } from 'react-device-detect';
const listSocial = [
  {
    icon: TwitterIcon,
    link: 'https://twitter.com/desktoparmy_nft',
  },
  // {
  //   icon: FbIcon,
  //   link: 'https://twitter.com/home',
  // },
  // {
  //   icon: TeleIcon,
  //   link: 'https://twitter.com/home',
  // },
  {
    icon: DiscordIcon,
    link: 'https://discord.gg/AyNDAyFkvZ',
  },
];

const AppFooter = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.containerFooter}>
      <div className={styles.wrapperFooter}>
        <Row className={styles.contentFooter}>
          <Col xs={12} sm={6}>
            <ul>
              <li>
                <a href={paths.companyProflie}>{t('footer.companyProfile')}</a>
              </li>
              <li>
                <a href={paths.contactUs}>{t('footer.contactUs')}</a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={6}>
            <ul>
              <li>
                {/*<image />*/}
                <a href={paths.attentionWhenUsing}>{t('footer.attentionWhenUsing')}</a>
              </li>
              <li style={{ cursor: 'pointer' }}>
                <a target='_blank' href="https://upbond.notion.site/WHITE-PAPER-JP-3bda39c042074f16980fa1ac1b6e1e80">
                {t('footer.helpCenter')}
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={6}>
            <ul>
              <li>
                <a href={paths.privacyandpolicy}>{t('footer.privacyPolicy')}</a>
              </li>
              <li>
                <a target='_blank' href="https://upbond.notion.site/ce8b1af36f8c4de294796ce21b40aa5c">{t('footer.guideline')}</a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={6}>
            <ul>
              <li>
                <a href={paths.terms}>{t('footer.termOfUse')}</a>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
      <div className={styles.boxLicense}>
        <div className={styles.boxLicenseInline}>
          <p className={styles.license} style={{ marginBottom: 0 }}>
            COPYRIGHT 2005-2023 MEGAHOUSE CORPORATION. ALL RIGHTS RESERVED. <br /> ©MegaHouse
          </p>
          <div className={styles.listIconSocial}>
            {listSocial.map((item, index) => (
              <div key={index} className={styles.boxIcon}>
                <a href={item.link} target="_blank" rel="noreferrer">
                  <img src={item.icon} alt="" />
                </a>
              </div>
            ))}
            <div
                style={{
                  backgroundImage: `url(${pendingBg})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: isMobile ? 143 : 220,
                  height: isMobile ? 30 : 46,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 24
                }}
              >
                <p>{t('footer.patentPending')}</p>
              </div>
            <div style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
              <img style={{ height: 40, marginRight: 24 }} src={BandaiIcon} alt="" />
              {/* <div className={styles.logo} style={{ backgroundImage: `url(${MegahouseIcon})` }}></div> */}
              {/* <div className={styles.logo} style={{ backgroundImage: `url(${UpbondIcon})` }}></div> */}
              <img style={{ width: 66.3, height: 79.996, marginRight: 24 }} src={MegahouseIcon} alt="" />
              <img style={{ width: 66.3, height: 79.996 }} src={UpbondIcon} alt="" />
              {/* <img style={{ height: isMobile ? 30 : 66, marginRight: 24, width: 55 }} src={MegahouseIcon} alt="" />
              <img style={{ height: isMobile ? 30 : 66, width: 55 }} src={UpbondIcon} alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
