import React from 'react';
import NotFoundLogoDefault from 'assets/images/not-found-logo-0.5x.png';
import NotFoundLogo1X from 'assets/images/not-found-logo-1x.png';
import NotFoundLogo2X from 'assets/images/not-found-logo-2x.png';
import { paths } from 'routes/routes';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onBackHome = () => {
    navigate(paths.home);
  };

  return (
    <div className="not-found">
      <div>
        <div className="not-found__image-container">
          <span>4</span>
          <img
            src={NotFoundLogoDefault}
            srcSet={`${NotFoundLogoDefault} 600w, ${NotFoundLogo1X} 800w, ${NotFoundLogo2X} 1600w`}
            alt="not found img"
          />
          <span>4</span>
        </div>

        <div className="not-found__main">
          <h1>{t('notFound.title')}</h1>
          <h2>{t('notFound.subtitle')}</h2>
          <button className="btn btn--small btn--light-green" onClick={onBackHome}>
            {t('notFound.btnGoBack')}
          </button>
        </div>

        <div className="not-found__footer">
          <h3> {t('notFound.reason.title')}</h3>
          <ul>
            <li>{t('notFound.reason.reason-1')}</li>
            <li>{t('notFound.reason.reason-2')}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
