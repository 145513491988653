import { DeleteOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { notification, Tooltip } from 'antd';
import UploadIcon from 'assets/images/icons/upload.svg';
import ModelViewer from 'components/model-viewer';
import { IUploadType, UPLOAD_TYPE } from 'models/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { shortenName } from 'utils/short-word';

interface Props {
  onChange?: (value: any) => void;
  value?: {
    file: any;
    name: string;
  };
  maxSize: number;
  shape?: IUploadType;
  defaultImage?: any;
  size: {
    width: number | string;
    height: number | string;
  };
  type: UPLOAD_TYPE;
  updateProfile?: boolean;
  uploadIllustration?: boolean;
}

const getBase64 = (img: any, callback: (url: string) => void) => {
  const reader: any = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const ImageUpload = ({
  onChange,
  value,
  maxSize,
  shape,
  defaultImage,
  size,
  type,
  updateProfile = false,
  uploadIllustration = false,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [filePreview, setFilePreview] = useState<any>();
  const { t } = useTranslation();
  const id = Math.random();

  const fileAccept = () => {
    switch (type) {
      case UPLOAD_TYPE.IMAGE:
        return '.png, .jpeg, .jpg';
      case UPLOAD_TYPE.MODEL:
        return '.png, .jpeg, .jpg, .zip, .obj, .stl, .fbx, .blend';
      case UPLOAD_TYPE.PREVIEW3D:
        return '.glb, .gltf';
      case UPLOAD_TYPE.PREVIEW2D:
        return '.png, .jpeg, .jpg';
      case UPLOAD_TYPE.ILLUSTRATION:
        return '.png, .jpeg, .jpg, .gif';
      case UPLOAD_TYPE.ILLUSTRATION_DATA:
        return '.png, .jpeg, .jpg, .gif, pdf, .zip';
      default:
        return '.png, .jpeg, .jpg';
    }
  };

  const uploadButton = (
    <div className="uploadButton">{loading ? <LoadingOutlined /> : <UploadOutlined />}</div>
  );

  const handleChange = (info: any) => {
    const file = info?.target?.files?.[0];
    const isLtMax = file.size / 1024 / 1024 < maxSize;
    const name = file?.name;

    const fileType = `.${file?.name.split('.').pop()}`;

    if (!fileAccept().includes(fileType)) {
      notification.error({
        message: 'Error',
        description: t('notifications.typeUpload', { types: fileAccept() }),
        duration: 4,
      });
      return;
    }

    if (!isLtMax) {
      notification.error({
        message: 'Error',
        description: t('notifications.maxSize', { maxSize }),
        duration: 4,
      });
      return;
    }
    if (file) {
      onChange && onChange({ file, name });
    } else {
      return;
    }
  };

  const RenderPreview = () => {
    switch (type) {
      case UPLOAD_TYPE.IMAGE:
        return (
          <label htmlFor={String(id)}>
            <div className="input-upload-image">
              <div className="imageHover">{uploadButton}</div>
              {/* <img
                src={filePreview ? filePreview : defaultImage}
                alt="avatar"
                style={{ width: '100%' }}
              /> */}
              <div
                className="image-file"
                style={{ backgroundImage: `url(${filePreview ? filePreview : defaultImage})` }}
              ></div>
            </div>
          </label>
        );
      case UPLOAD_TYPE.MODEL:
        return (
          <div className="input-upload-model">
            <div className="input-box">
              <div className="input-name">
                <Tooltip title={value?.name || fileAccept()}>
                  {shortenName(value?.name || fileAccept(), 25)}
                </Tooltip>
              </div>
              <label htmlFor={String(id)}>
                <div className="model-button">{t('common.select')}</div>
              </label>
            </div>
          </div>
        );
      case UPLOAD_TYPE.PREVIEW3D:
        return (
          <div className="input-upload-preview">
            {value?.file ? (
              <div className="viewer-box">
                <label htmlFor={String(id)}>
                  <img className="upload-button" src={UploadIcon} alt="button" />
                </label>
                <ModelViewer src={filePreview} width={'100%'} height={'100%'} />
              </div>
            ) : (
              <label htmlFor={String(id)}>
                <div className="preview-upload">
                  <img src={UploadIcon} alt="upload" />
                  <p>{t('common.preview3d')}</p>
                  <span>({fileAccept()})</span>
                </div>
              </label>
            )}
          </div>
        );
      case UPLOAD_TYPE.PREVIEW2D:
        return (
          <div className="input-upload-preview">
            {value?.file ? (
              <div className="viewer-box">
                <label htmlFor={String(id)}>
                  <img className="upload-button" src={UploadIcon} alt="button" />
                </label>
                {/* <img
                  src={filePreview}
                  alt="2d Preview"
                  style={{ width: '100%' }}
                  className="img-preview2d"
                /> */}
                <div
                  className="image-file"
                  style={{ backgroundImage: `url(${filePreview})` }}
                ></div>
              </div>
            ) : (
              <label htmlFor={String(id)}>
                <div className="preview-upload">
                  <img src={UploadIcon} alt="upload" />
                  <p>{t('common.preview2d')}</p>
                  <span>({fileAccept()})</span>
                </div>
              </label>
            )}
          </div>
        );
      case UPLOAD_TYPE.ILLUSTRATION:
        return (
          <div className="input-upload-preview" style={{ marginBottom: 20 }}>
            {value?.file ? (
              <>
                <div className="viewer-box">
                  <label htmlFor={String(id)}>
                    <img className="upload-button" src={UploadIcon} alt="button" />
                  </label>
                  <div
                    className="image-file"
                    style={{
                      backgroundImage: `url(${filePreview})`,
                    }}
                  ></div>
                </div>
              </>
            ) : (
              <label htmlFor={String(id)}>
                <div className="preview-upload">
                  <img src={UploadIcon} alt="upload" />
                  <p>
                    {!uploadIllustration ? t('common.preview2d') : t('common.dataIllustration')}
                  </p>
                  <span>({fileAccept()})</span>
                </div>
              </label>
            )}
          </div>
        );
      case UPLOAD_TYPE.ILLUSTRATION_DATA:
        return (
          <div className="input-upload-model">
            <div className="input-box">
              <div className="input-name">
                <Tooltip title={value?.name || fileAccept()}>
                  {shortenName(value?.name || fileAccept(), 25)}
                </Tooltip>
              </div>
              <label htmlFor={String(id)}>
                <div className="model-button">{t('common.select')}</div>
              </label>
            </div>
          </div>
        );
      default:
        return (
          <div className="input-upload-image">
            <div className="imageHover">{uploadButton}</div>
            <img
              src={filePreview ? filePreview : defaultImage}
              alt="avatar"
              style={{ width: '100%' }}
            />
          </div>
        );
    }
  };

  useEffect(() => {
    if (!value?.file) {
      setFilePreview(null);
      return;
    }
    if (typeof value?.file === 'string') {
      setFilePreview(value?.file);
    } else {
      value?.file &&
        getBase64(value.file, (url) => {
          setFilePreview(url);
        });
    }
    value?.file && setFilePreview(value?.file);
  }, [value]);

  return (
    <div
      className={shape === IUploadType.CIRCLE ? 'imageUploaderCircle' : 'imageUploaderRetangle'}
      style={size}
    >
      <input
        accept={fileAccept()}
        type="file"
        onChange={handleChange}
        id={String(id)}
        style={{ display: 'none' }}
      />
      <RenderPreview />
      {updateProfile && value?.file && (
        <DeleteOutlined
          onClick={() => onChange && onChange({ file: null, name: null })}
          className="icon-reset-image"
        />
      )}
    </div>
  );
};

export default ImageUpload;
