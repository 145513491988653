import { Theme } from 'constants/common';
import { createContext, useCallback, useState } from 'react';
import localStorageHelper, { KeyStorage } from 'utils/localStorage';

const initialValue = {
  theme: Theme.LIGHT,
  setTheme: () => null,
};
export const ThemeContext = createContext<any>(initialValue);

const ThemeProvider = ({ children }: any) => {
  const themeStorage = localStorageHelper.get(KeyStorage.THEME);
  const [theme, setTheme] = useState(themeStorage || Theme.LIGHT);
  const toggleTheme = useCallback(() => {
    setTheme((curr: string) => (curr === Theme.LIGHT ? Theme.DARK : Theme.LIGHT));
    localStorageHelper.set(
      KeyStorage.THEME,
      themeStorage === Theme.LIGHT ? Theme.DARK : Theme.LIGHT
    );
  }, [themeStorage]);
  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;
