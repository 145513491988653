import { combineReducers } from 'redux';

import user from './user/userSlice';
import system from './system/systemSlice';
import buyNft from './buy-nft/slice';
import master from './master/slice';
import nft from './common/nftSlice';


// red

const rootReducer = combineReducers({
  system,
  master,
  user,
  buyNft,
  nft
});

export default rootReducer;
