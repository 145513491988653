import './styles.scss';
import '@google/model-viewer';

const ModelViewer = (props) => {
  const { src, width, height, style, className, ...rest } = props;
  return (
    <model-viewer
      {...rest}
      className={className}
      src={src}
      ar
      ar-modes="webxr scene-viewer quick-look"
      camera-controls
      shadow-intensity="1"
      autoplay
      disable-pan
      style={{ width: width, height: height, ...style }}
      xr-environment
    ></model-viewer>
  );
};

export default ModelViewer;
