import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchBackground, fetchCharacter, fetchWeaponPart } from './actions';
import { Background, Character, WeaponPosition } from './type';

export type MasterDataState = {
  characters: Character[];
  parts: WeaponPosition[];
  backgrounds: Background[];
};

const initialState: MasterDataState = {
  characters: [],
  parts: [],
  backgrounds: [],
};

export const MasterDataSlice = createSlice({
  name: 'masterData',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCharacter.fulfilled, (state, action) => {
      state.characters = action.payload;
    });
    builder.addCase(fetchBackground.fulfilled, (state, action) => {
      state.backgrounds = action.payload;
    });
    builder.addCase(fetchWeaponPart.fulfilled, (state, action) => {
      state.parts = action.payload;
    });
  },
});

// export const {} = MasterDataSlice.actions;

export default MasterDataSlice.reducer;
