enum API_PREFIX {
  CLIENT = 'client',
  MANAGEMENT = 'management',
  MASTER = 'master',
}

export const API = {
  // auth
  verifyUser: `/${API_PREFIX.CLIENT}/users/verify`,
  logout: `/${API_PREFIX.CLIENT}/users/logout`,
  checkBlockUser: `/${API_PREFIX.CLIENT}/users/verify/wallet-address`,

  //my-page
  listMyPageNft: `/${API_PREFIX.CLIENT}/nfts`,
  listTransaction: `/${API_PREFIX.CLIENT}/transactions`,
  detailTransaction: `/${API_PREFIX.CLIENT}/transactions`,
  detailNftMyPage: `/${API_PREFIX.CLIENT}/nfts`,
  profileUser: `/${API_PREFIX.CLIENT}/users`,
  presignUser: `/${API_PREFIX.CLIENT}/users/presign`,

  //artist
  nftList: `/${API_PREFIX.CLIENT}/artists/`,

  //like
  likeNft: `/${API_PREFIX.CLIENT}/likes`,

  //nftDetail
  firstNftDetail: `/${API_PREFIX.CLIENT}/me/nfts/first-nft/`,
  secondNftDetail: `/${API_PREFIX.CLIENT}/me/nfts/second-nft/`,

  //1st-nft
  nftList1stBuy: `/${API_PREFIX.CLIENT}/first-nft`,
  nftList1stIllustrationBuy: `/${API_PREFIX.CLIENT}/first-nft-simple`,
  nftDetail1stBuy: `/${API_PREFIX.CLIENT}/first-nft/{{id}}`,
  getDetailCombined: `/${API_PREFIX.CLIENT}/first-nft/detail-combined`,
  nftOwnedList: `/${API_PREFIX.CLIENT}/first-nft-owned`,

  //2nd-nft
  nftList2nd: `/${API_PREFIX.CLIENT}/second-nft`,
  presignImage: `/${API_PREFIX.CLIENT}/second-nft/presign`,

  //master
  getCharacter: '/characters',
  getPart: `/positions`,
  getBackground: `/${API_PREFIX.MASTER}/background-customize`,

  //noti
  notifications: `/${API_PREFIX.CLIENT}/system-notification`,
  notiList: `/${API_PREFIX.CLIENT}/notifications`,
  markRead: `/${API_PREFIX.CLIENT}/notifications/mark-read`,

  //payment
  payment: `/${API_PREFIX.CLIENT}/payments/charge`,

  //ntf
  nft: `/${API_PREFIX.CLIENT}/nfts`,
  nftDetail: `/${API_PREFIX.CLIENT}/nfts/{{id}}`,

  //getLinkDownload
  getLink: `/${API_PREFIX.CLIENT}/users/get-link`,

  // upload 2nd nft
  upload2ndNft: `/${API_PREFIX.CLIENT}/second-nft/upload`,
  presign2ndNftFile: `/${API_PREFIX.CLIENT}/second-nft/presign`,

  // upload 2nd nft simple
  uploadSimple2ndNft: `/${API_PREFIX.CLIENT}/second-nft-simple/upload`,
  presignSimple2ndNftFile: `/${API_PREFIX.CLIENT}/second-nft-simple/presign`,




  //contact-use
  contactUs: `/${API_PREFIX.MANAGEMENT}/inquiry`,

  //transaction
  getTransactionStatistic: `/${API_PREFIX.CLIENT}/transactions/statistic`,

  contracts: 'contracts',
  // accept term 

  acceptTerm: `/${API_PREFIX.CLIENT}/users/accept-term`,
};
