import { DownloadOutlined } from '@ant-design/icons';
import { Avatar, Col, Row, Typography, notification } from 'antd';
import DefaultAvatar from 'assets/images/avatar-default.png';
import ModelViewer from 'components/model-viewer';
import { LANGUAGE, SECOND_NFT_TYPE, STATUS_NFT_2ND } from 'constants/common';
import useGetImageNft from 'hooks/useGetImageNft';
import useGetImageDetailNft from 'hooks/useGetImageDetailNft';
import { NftDataResDetail, NFT_IMAGE_TYPE } from 'models/nft';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './model-card.module.scss';
import { isMobile } from 'react-device-detect';
import { UserProfile } from 'models/common';
import { shortenAddress } from 'utils/short-word';
import { changeStatusNft2ndMutiLanguage, getCharacterName } from 'utils';
import DefaultCover from 'assets/images/cover-default.png';
const { Title, Text } = Typography;

type StatisticProps = {
  color?: string;
};

const StatisticInfo: React.FC<PropsWithChildren<StatisticProps>> = (props) => {
  const { color } = props;
  return (
    <div
      className={styles['statistic-item']}
      style={{ backgroundColor: color, borderWidth: color ? 0 : 1 }}
    >
      {props.children}
    </div>
  );
};

type ModelProps = {
  data: NftDataResDetail | null;
  profileUser: UserProfile | undefined;
};

const ModelCard2nd: React.FC<ModelProps> = (props) => {
  const { data, profileUser } = props;
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const getPreview2dImageNft = useGetImageNft(data?.images, NFT_IMAGE_TYPE.PREVIEW_2D);
  const getPreview3dImageNft = useGetImageNft(data?.images, NFT_IMAGE_TYPE.PREVIEW);
  const getModal3dNft = useGetImageNft(data?.images, NFT_IMAGE_TYPE.MODEL);
  const getPreview3dImageDetailNft = useGetImageDetailNft(data?.images, NFT_IMAGE_TYPE.PREVIEW);
  const getModal3dDetailNft = useGetImageDetailNft(data?.images, NFT_IMAGE_TYPE.MODEL);

  const labelPropertyUpload2nd = [
    t('MyPage.detail2ndRequest.propertyNft.team'),
    t('MyPage.detail2ndRequest.propertyNft.baseBody'),
    t('MyPage.detail2ndRequest.propertyNft.fileFormat'),
    t('MyPage.detail2ndRequest.propertyNft.others'),
  ];

  const secondTypeNft = () => {
    if (lang === LANGUAGE.JAPAN) {
      if (data?.secondType === SECOND_NFT_TYPE.COMBINE) return '組み合わせ';
      return 'ユーザがカスタマイズ';
    }
    return data?.secondType;
  };

  const handleDownload = async (params) => {    
    const fileUrl = params?.objectKey;
    const fileName = params?.name;

    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName; // Set the desired file name

      // Append the link to the body and trigger the click event
      document.body.appendChild(a);
      a.click();

      // Remove the link from the document
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);      
      notification.error({
        message: 'Error',
        description: 'Error downloading file, it seems you have no access to download this file or the file is broken.',
        duration: 4,
      });
    }
  };

  return (
    <>
      <div className={`${styles['model-card']} ${styles['model-card--2nd']} `}>
        <Text className={styles['model-card__name']}>
          {t('MyPage.detail2ndRequest.cardNameNft')}
        </Text>
        <Row gutter={[30, 16]}>
          <Col
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            className={styles['left-content']}
          >
            <div className={styles.modelWrapper}>
              {!data?.is2D ? (
                <ModelViewer
                  src={getPreview3dImageNft}
                  width="100%"
                  height="100%"
                  background="transparent"
                />
              ) : (
                <div
                  style={{
                    background: `url(${getPreview2dImageNft || DefaultCover})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: '#FFFFFF',
                    backgroundPosition: 'center',
                    height: '100%',
                  }}
                ></div>
              )}
            </div>

            <div
              className={styles.actions}
              onClick={() => handleDownload(data?.is2D ? getPreview3dImageDetailNft : getModal3dDetailNft)}
            >
              <DownloadOutlined />
              <p> {t('MyPage.detail2ndRequest.download')}</p>
            </div>
          </Col>
          <Col
            lg={{ span: 16 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            className={styles['right-content']}
          >
            <div className={styles['header-statistic']}>
              <Row gutter={[16, 16]}>
                <Col md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <Row className={styles.row}>
                    <Col
                      md={{ span: 6 }}
                      sm={{ span: 12 }}
                      xs={{ span: 12 }}
                      className={styles.label}
                    >
                      {t('MyPage.detail2ndRequest.title')}
                    </Col>
                    <Col md={{ span: 18 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                      <b>{getCharacterName(data?.characterName)}</b>
                    </Col>
                  </Row>
                  <Row className={styles.row}>
                    <Col
                      md={{ span: 6 }}
                      sm={{ span: 12 }}
                      xs={{ span: 12 }}
                      className={styles.label}
                    >
                      {t('MyPage.detail2ndRequest.status')}
                    </Col>
                    <Col md={{ span: 9 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                      <StatisticInfo>
                        <b
                          style={{
                            color:
                              data?.secondNftStatus === STATUS_NFT_2ND.REJECT
                                ? '#ff7070'
                                : '#4ac86d',
                          }}
                        >
                          {changeStatusNft2ndMutiLanguage(data?.secondNftStatus as string, lang)}
                        </b>
                      </StatisticInfo>
                    </Col>
                  </Row>
                  <Row className={styles.row}>
                    <Col
                      md={{ span: 6 }}
                      sm={{ span: 12 }}
                      xs={{ span: 12 }}
                      className={styles.label}
                    >
                      {t('MyPage.detail2ndRequest.type')}
                    </Col>
                    <Col md={{ span: 18 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                      <b style={{ fontSize: isMobile ? 13 : 14 }}>{secondTypeNft()}</b>
                    </Col>
                  </Row>
                </Col>

                <Col md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  {isMobile && (
                    <b style={{ marginBottom: 10, display: 'inline-block' }}>
                      {t('MyPage.detail2ndRequest.propertyNft.title')}
                    </b>
                  )}
                  {data?.propertiesSecondNft?.length &&
                    data?.propertiesSecondNft[0].value &&
                    data?.propertiesSecondNft?.map((item, index) => (
                      <Row className={styles.row}>
                        <Col
                          md={{ span: 7 }}
                          sm={{ span: 12 }}
                          xs={{ span: 12 }}
                          className={styles.label}
                        >
                          {labelPropertyUpload2nd[index]}
                        </Col>
                        <Col md={{ span: 17 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                          <b style={{ wordBreak: 'break-all' }}>{item?.value}</b>
                        </Col>
                      </Row>
                    ))}
                </Col>
              </Row>
            </div>
            <Title level={isMobile ? 4 : 2}>{data?.title}</Title>
            <Text>{data?.description}</Text>
            <div className={styles['artist-list']}>
              <div className={styles['artist-item']}>
                <Avatar size="large" src={profileUser?.avatarImg || DefaultAvatar} />
                <Text className={styles['creator-item__name']}>{profileUser?.userName}</Text>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ModelCard2nd;
