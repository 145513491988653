import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NftState {
  data: any;
}

const initialState: NftState = {
  data: {}
};

export const NftSlice = createSlice({
  name: 'nft',
  initialState,
  reducers: {
    setSelectedNftState(state: NftState, action: PayloadAction<any>) {
      state.data = action.payload;
    },
  },
});

export const { setSelectedNftState } = NftSlice.actions;

export default NftSlice.reducer;
