import { Modal, Pagination, Table, Tooltip, notification } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DataResTransactions } from 'models/my-page';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from 'utils/api/api-route';
import ApiUtils from 'utils/api/api.utils';
import { shortenName } from 'utils/short-word';
import styles from '../styles.module.scss';
import TransactionDetail from './modal-transaction-deatail';
import { formatNumber } from 'utils';
import { LANGUAGE } from 'constants/common';

const LIMIT = 10;

interface InitFilter {
  limit: number;
  page: number;
}

const initFilter = {
  limit: LIMIT,
  page: 1,
  sortField: 'created_at',
  desc: true,
};

const TableTransactions = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState<DataResTransactions | null>(null);
  const [filter, setFilter] = useState<InitFilter>(initFilter);
  const [dataResponse, setDataResponse] = useState<any>({
    items: [],
    totalCount: 0,
  });

  const getTransactions = useCallback(async () => {
    setLoading(true);
    try {
      const res = await ApiUtils.fetch(API.listTransaction, filter);
      if (res) {
        setDataResponse(res);
      }
    } catch (error) {
      console.error(`Error transaction`, JSON.stringify(error));
      notification.error({ message: 'Error', description: t('common.messageWrong'), duration: 4 });
    } finally {
      setLoading(false);
    }
  }, [filter, t]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  const handleChangePage = (page: number) => {
    setFilter((preState) => ({ ...preState, page }));
  };

  const onclickDetailTransaction = (item) => {
    setIsModalOpen(true);
    setItemSelected(item);
  };

  const columns: ColumnsType<DataResTransactions> = [
    {
      title: t('MyPage.TableTransaction.item'),
      dataIndex: 'nft',
      key: 'nft',
      render: (text) => (
        <Tooltip title={text}>{shortenName(text.replace('__AND__', ', ') || '', 30) || ''}</Tooltip>
      ),
    },
    {
      title: `${t('MyPage.TableTransaction.amount')}(${t('Payment.includeTax')})`,
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (_, record) => {
        const totalPrice = lang === LANGUAGE.JAPAN ? Number(record?.totalAmount) :  Number(record?.totalAmountUsd);
        return (
          <span>
            {formatNumber(totalPrice, lang)}
          </span>
        );
      },
    },
    {
      title: t('MyPage.TableTransaction.status'),
      dataIndex: 'displayStatus',
      key: 'status',
    },
    {
      title: t('MyPage.TableTransaction.purchaseDate'),
      dataIndex: 'purchaseDate',
      key: 'purchaseDate',
      render: (text) => <span>{moment(text).format('L')}</span>,
    },
    {
      title: t('MyPage.TableTransaction.action'),
      key: 'action',
      render: (_, record) => (
        <button
          className={styles.btnActionTransaction}
          onClick={() => onclickDetailTransaction(record)}
        >
          {t('MyPage.TableTransaction.btnView')}
        </button>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={dataResponse.items}
        pagination={false}
        loading={loading}
        scroll={{ x: 500 }}
      />
      <div className={styles.paginationTransaction}>
        {dataResponse.totalCount > LIMIT && (
          <Pagination
            showSizeChanger={false}
            current={filter.page}
            defaultPageSize={LIMIT}
            onChange={handleChangePage}
            showLessItems
            total={dataResponse.totalCount}
          />
        )}
      </div>
      {isModalOpen && (
        <Modal
          width={800}
          okButtonProps={{ style: { display: 'none' } }}
          title={t('MyPage.detailTransaction.title')}
          open={isModalOpen}
          closable={true}
          onCancel={() => setIsModalOpen(false)}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <TransactionDetail id={itemSelected?.id} />
        </Modal>
      )}
    </div>
  );
};

export default TableTransactions;
