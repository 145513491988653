import { Avatar, Col, Row, Tooltip, Typography } from 'antd';
import LogoMegahouse from 'assets/images/logo-megahouse.png';
import DefaultCover from 'assets/images/cover-default.png';
import DefaultAvatar from 'assets/images/avatar-default.png';
import ModelViewer from 'components/model-viewer';
import React, { PropsWithChildren, useTransition } from 'react';
import { ItemEquipment } from './detail-2st-approve-reject-modal';
import styles from './model-card.module.scss';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

type StatisticProps = {
  color?: string;
};

const StatisticInfo: React.FC<PropsWithChildren<StatisticProps>> = (props) => {
  const { color } = props;

  return (
    <div
      className={styles['statistic-item']}
      style={{ backgroundColor: color, borderWidth: color ? 0 : 1 }}
    >
      {props.children}
    </div>
  );
};

type ModelProps = {
  data: ItemEquipment;
};

const ModelCardEquipment: React.FC<ModelProps> = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  const isWeaponCard = data.type === 'weapon';

  return (
    <>
      <div className={`${styles['model-card']}`}>
        <Text className={styles['model-card__name']}>
          {!data?.is2d ? data.cardName : t('MyPage.detailNFT.equipment')}
        </Text>

        <Row gutter={[16, 16]}>
          <Col
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            className={styles['left-content']}
          >
            <div className={styles.modelWrapper}>
              {isWeaponCard ? (
                // <img src={data.preView3d || DefaultCover} alt="2d" />

                <div
                  style={{
                    background: `url(${data.preView3d || DefaultCover})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: '#FFFFFF',
                    backgroundPosition: 'center',
                    height: '100%',
                  }}
                ></div>
              ) : (
                <ModelViewer
                  src={data.preView3d}
                  width="100%"
                  height="100%"
                  background="transparent"
                />
              )}
            </div>
          </Col>
          <Col
            lg={{ span: 16 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            className={styles['right-content']}
          >
            <Title level={isMobile ? 4 : 3}>{data?.title}</Title>
            <Text>{data?.description}</Text>
            <div className={styles['artist-list']}>
              <Col md={{ span: 12 }} xs={{ span: 14 }}>
                <div className={styles['creator-item']}>
                  <Avatar size="large" src={LogoMegahouse} />
                  <Text className={styles['creator-item__name']}>Megahouse</Text>
                </div>
              </Col>
              <Col className={styles['list-owner-item']} md={{ span: 12 }} xs={{ span: 10 }}>
                {data?.owners &&
                  data?.owners.length > 0 &&
                  data?.owners.map((item, index) => {
                    if (isMobile && index > 1) {
                      return null;
                    }
                    return (
                      <Tooltip
                        title={item?.userName || item?.walletAddress}
                        placement="top"
                        key={'top' + index}
                      >
                        <Avatar
                          style={{ marginLeft: '-8px' }}
                          size="large"
                          src={item?.presignUrl || DefaultAvatar}
                        />
                      </Tooltip>
                    );
                  })}
                {!isMobile
                  ? data?.countOwner > 4 && (
                      <span style={{ fontSize: 16, marginLeft: 5 }}>+{data.countOwner - 4}</span>
                    )
                  : data?.countOwner > 2 && (
                      <span style={{ fontSize: 16, marginLeft: 5 }}>+{data.countOwner - 2}</span>
                    )}
              </Col>
            </div>

            {!data?.is2d && (
              <div className={styles['model-card__footer']}>
                <div className={styles.statistics}>
                  <StatisticInfo>{data.cardName}</StatisticInfo>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ModelCardEquipment;
