import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LANGUAGE } from 'constants/common';
import localStorageHelper, { KeyStorage } from 'utils/localStorage';


export interface SystemState {
    globalLoading: boolean,
    languageSystem: string,
    locationSystem: string
}

const initialState: SystemState = {
    globalLoading: false,
    languageSystem: localStorageHelper.get(KeyStorage.I18n),
    locationSystem: "",
};


export const SystemSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        loadingSystem: (state) => {
            state.globalLoading = true
        },
        loadingSystemSuccess: (state) => {
            state.globalLoading = false
        },
        changeLanguageAction: (state, action: PayloadAction<string>) => {
            state.languageSystem = action.payload
        },
        updateLocationSystem: (state, action: PayloadAction<string>) => {
            state.locationSystem = action.payload
        },
    },


});

export const { loadingSystem, loadingSystemSuccess, changeLanguageAction, updateLocationSystem } =
    SystemSlice.actions;

export default SystemSlice.reducer;
