export const LIMIT = 10;

export enum Action {
  EDIT = 'Edit',
  REMOVE = 'Remove',
}

export enum Character {
  Sylphy = 'シルフィ',
  Fya = 'フレイヤ',
  BerilPawn = 'ベリル',
  BerilKnight = 'ベリル ナイト',
  BerilBishop = 'ベリル ビショップ',
  BerilLuke = 'ベリル ルーク',
  BerilKing = 'ベリル キング',
  BerilQueen = 'ベリル クイーン',
  ShadePawn = 'シェイド',
  ShadeKnight = 'シェイド ナイト',
  ShadeBishop = 'シェイド ビショップ',
  ShadeLuke = 'シェイド ルーク',
  ShadeKing = 'シェイド キング',
  ShadeQueen = 'シェイド クイーン',
}

export enum Theme {
  LIGHT = 'light-theme',
  DARK = 'dark-theme',
}

export enum LANGUAGE {
  JAPAN = 'ja',
  ENGLISH = 'en',
}

export enum STATUS_NFT_2ND {
  REJECT = 'REJECT',
  PENDING = 'PENDING',
  APPROVE = 'APPROVE',  
  REVIEW = 'REVIEW',
}

export enum TYPE_NFT {
  JAPAN = 'first_nft',
  ENGLISH = 'second_nft',
}

export enum TYPE_1ST {
  Body = 'body',
  Weapon = 'weapon',
  Preset = 'preset',
  All = 'all',
  Illustration = 'illustration',
}

export enum TYPE_2ND {
  Weapon = 'weapon',
  Preset = 'preset',
}

export enum USER_IMAGE_TYPE {
  AVATAR = 'avatar',
  COVER = 'cover',
}

export enum WINDOWN_SIZE {
  XXL = 1440,
  XL = 1200,
  DESKTOP = 1024,
  TABLET = 768,
  MOBILE = 428,
  M_MOBILE = 375,
  SM_MOBILE = 320,
}
// check from src/assets/styles/variables.scss

export enum NOTI_SOCKET {
  APPROVED_SECOND_NFT = 'APPROVED_SECOND_NFT',
  REJECT_SECOND_NFT = 'REJECT_SECOND_NFT',
  USER_LIKE_NFT = 'USER_LIKE_NFT',
}
const ALCHEMY_API_KEY_MUMBAI = 'ShLcL8vKFavtHXNX7RWHYedOlow0uKan';
export const WALLETCONNECT_BRIDGE_URL = 'https://bridge.walletconnect.org';

export const NETWORK_URLS = {
  137: `https://polygon-rpc.com/`,
  80002: `https://polygon-amoy.g.alchemy.com/v2/${ALCHEMY_API_KEY_MUMBAI}`,
};

export const MESSAGE_SIGN = 'DTA_DOWNLOAD';

export enum FILTER_TIME_RANKING {
  ONE_HOUR = 'one hour',
  SIX_HOURS = 'six hours',
  TWENTY_FOUR_HOURS = 'twenty four hours',
  ONE_WEEK = 'one week',
  ONE_MONTH = 'one month',
  ALL = 'all',
}
export enum SECOND_NFT_TYPE {
  COMBINE = 'COMBINE',
  USER_CUSTOMIZE = 'USER_CUSTOMIZE',
}

export enum FILE_UPLOAD_SUCCESS {
  UPLOAD_PREVIEW_SECOND_NFT_SUCCESS = 'UPLOAD_PREVIEW_SECOND_NFT_SUCCESS',
  UPLOAD_MODEL_3D_SECOND_NFT_SUCCESS = 'UPLOAD_MODEL_3D_SECOND_NFT_SUCCESS',
  UPLOAD_PREVIEW2D_SECOND_NFT_SUCCESS = 'UPLOAD_PREVIEW2D_SECOND_NFT_SUCCESS',
}

export enum NAME_NFTS {
  Shylphy = 'Shylphy',
  Fya = 'Fya',
}

export enum PROPERTY_UPLOAD_SECOND {
  COUNTRY = 'Country',
  BODY_NAME = 'Body Name',
  FILE_FORMAT = 'File Format',
  OTHER = 'Other',
}
