import { NotiDetailType, NOTIFICATION_TYPE } from 'models/notification';
import { useCallback, useEffect, useState } from 'react';
import { getListNoti, markRead } from 'services/notification-service';
import styles from '../styles.module.scss';

import NotiDetail from './noti-detail';
import LoadingUpbond from 'components/loading-upbond';
import { checkToken } from 'utils';
import { getUserInfo } from 'utils/auth/auth.utils';
import socket from 'utils/socket/socket';
import { NOTI_SOCKET } from 'constants/common';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

interface IProps {
  openNotice: boolean;
  handleHaveNoti: (isHave: boolean) => void;
  setOpenNotice: (value: boolean) => void;
}

interface IPaging {
  page: number;
  limit: number;
  desc: boolean;
  sortField: string;
}
const LIMIT = 10;

const initialPaging = {
  page: 1,
  limit: LIMIT,
  desc: true,
  sortField: 'created_at',
};

const ListNoti = ({ openNotice, handleHaveNoti, setOpenNotice }: IProps) => {
  const [listNoti, setListNoti] = useState<NotiDetailType[]>([]);
  const [pagingNoti, setPagingNoti] = useState<IPaging>(initialPaging);
  const [totalNoti, setTotalNoti] = useState<number>(0);
  const [loadingNoti, setLoadingNoti] = useState<boolean>(true);
  const [totalUnread, setTotalUnread] = useState<number>(0);
  const isLoadMore = loadingNoti && totalNoti > listNoti?.length;
  const token = checkToken();
  const userInfo = getUserInfo();
  const { t } = useTranslation();

  const renderNotiFirstCall = useCallback(() => {
    if (!listNoti || listNoti.length === 0) {
      if (loadingNoti) {
        return (
          <div className={styles.boxAlertLoading}>
            <LoadingUpbond />
          </div>
        );
      } else return t('notifications.notHaveNoti');
    }
  }, [listNoti, loadingNoti, t]);

  const handleScroll = (e: any) => {
    const target = e.target;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight && !loadingNoti) {
      setLoadingNoti(true);
      setPagingNoti({ ...pagingNoti, limit: pagingNoti.limit + LIMIT });
    }
  };

  const handleMarkRead = useCallback(
    (index) => {
      const listNotiClone = [...listNoti];
      listNotiClone[index] = { ...listNotiClone[index], isRead: 1 };
      setListNoti(listNotiClone);
      setTotalUnread(totalUnread > 0 ? totalUnread - 1 : 0);
    },
    [setListNoti, listNoti, totalUnread]
  );

  const handleMarkReadAll = useCallback(async () => {
    const result = await markRead();
    if (result) {
      const listNotiRead = listNoti.map((item: NotiDetailType) => {
        return { ...item, isRead: 1 };
      });
      setListNoti(listNotiRead);
      setTotalUnread(0);
    }
  }, [listNoti]);

  const handleGetListNoti = useCallback(async () => {
    const result = await getListNoti(pagingNoti);
    if (result) {
      setTotalNoti(result.totalCount);
      setTotalUnread(result.totalUnRead);
      setListNoti(result.items);
    }
    setLoadingNoti(false);
  }, [pagingNoti, setTotalNoti, setListNoti, setTotalUnread]);

  useEffect(() => {
    if (token && userInfo?.address) handleGetListNoti();
  }, [pagingNoti, token, userInfo?.address, handleGetListNoti]);

  useEffect(() => {
    token && setPagingNoti({ ...initialPaging });
  }, [token]);

  useEffect((): any => {
    socket.on(NOTI_SOCKET.USER_LIKE_NFT, (message: any) => {
      if (message) {
        const newNoti = {
          nftId: message?.nftId,
          nftName: message?.nftName,
          likeStatus: message?.status,
          fromUserId: message?.userId,
          fromUserWalletAddress: message?.walletAddress,
          userImage: message?.userImage,
          type: NOTIFICATION_TYPE.LIKES,
        };
        setListNoti((oldState: NotiDetailType[]) => [newNoti, ...oldState]);
        setTotalNoti((oldState: number) => oldState + 1);
        setTotalUnread((oldState: number) => oldState + 1);
      }
    });
    if (socket) return () => socket.off(NOTI_SOCKET.USER_LIKE_NFT);
  }, []);
  useEffect((): any => {
    socket.on(NOTI_SOCKET.APPROVED_SECOND_NFT, (message: any) => {
      if (message) {
        const newNoti = {
          nftId: message?.nftId,
          nftName: message?.nftName,
          nftImage: message?.nftImage,
          likeStatus: message?.status,
          fromUserId: message?.userId,
          fromUserWalletAddress: message?.walletAddress,
          type: NOTIFICATION_TYPE.SECOND_NFT_APPROVED,
        };
        setListNoti((oldState: NotiDetailType[]) => [newNoti, ...oldState]);
        setTotalNoti((oldState: number) => oldState + 1);
        setTotalUnread((oldState: number) => oldState + 1);
      }
    });
    if (socket) return () => socket.off(NOTI_SOCKET.APPROVED_SECOND_NFT);
  }, []);
  useEffect((): any => {
    socket.on(NOTI_SOCKET.REJECT_SECOND_NFT, (message: any) => {
      if (message) {
        const newNoti = {
          nftId: message?.nftId,
          nftName: message?.nftName,
          nftImage: message?.nftImage,
          likeStatus: message?.status,
          fromUserId: message?.userId,
          fromUserWalletAddress: message?.walletAddress,
          type: NOTIFICATION_TYPE.SECOND_NFT_REJECTED,
        };
        setListNoti((oldState: NotiDetailType[]) => [newNoti, ...oldState]);
        setTotalNoti((oldState: number) => oldState + 1);
        setTotalUnread((oldState: number) => oldState + 1);
      }
    });

    if (socket) return () => socket.off(NOTI_SOCKET.REJECT_SECOND_NFT);
  }, []);

  useEffect(() => {
    handleHaveNoti(totalUnread > 0 ? true : false);
  }, [totalUnread, handleHaveNoti]);

  return (
    <div
      className={isMobile ? styles.boxAlertMobile : styles.boxAlertDesktop}
      style={{ display: openNotice ? 'block' : 'none' }}
    >
      <div className={styles.boxAlertHeader}>
        <p className={styles.boxAlertTitle}>お知らせ</p>
        <button className="btn btn--light-green btn--small" onClick={handleMarkReadAll}>
          Read all
        </button>
      </div>
      <div className={styles.boxAlertList} onScroll={handleScroll}>
        {listNoti?.map((item: NotiDetailType, index: number) => {
          return (
            <NotiDetail
              item={item}
              handleMarkRead={handleMarkRead}
              index={index}
              setOpenNotice={setOpenNotice}
            />
          );
        })}
        {renderNotiFirstCall()}
        {isLoadMore && (
          <div className={styles.boxAlertLoading}>
            <LoadingUpbond />
          </div>
        )}
      </div>
    </div>
  );
};

export default ListNoti;
