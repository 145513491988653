import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

type Size = {
  width: number;
  height: number;
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    const debounceHanleSize = debounce(handleResize, 1000);

    handleResize();
    
    window.addEventListener('resize', debounceHanleSize);

    return () => window.removeEventListener('resize', debounceHanleSize);
  }, []);

  return windowSize;
};

export default useWindowSize;
