import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes/routes';
import { LANGUAGE, TYPE_1ST } from 'constants/common';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'utils';
import { ListResponse } from 'models/common';
import { FirstNftBuy, FirstNftBuyFilterRequest } from 'models/first-nft';
import Nft1stServices from 'services/1st-nft.service';
import { Tooltip } from 'antd';
import NotNft from 'components/not-found-nft';

const LIMIT = 3;

const params: FirstNftBuyFilterRequest = {
  page: 1,
  limit: LIMIT,
  filterType: TYPE_1ST.Preset,
  desc: true,
  sortField: 'created_at',
  isListCombined: false,
};

const RecommendPreset = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ListResponse<FirstNftBuy>>({ items: [], totalCount: 0 });

  useEffect(() => {
    const fetchWeapon = async () => {
      setLoading(true);
      const res = await Nft1stServices.getListBuy1stNft(params);
      if (res) {
        setData(res);
      }
      setLoading(false);
    };

    fetchWeapon();
  }, []);

  const handlerGotoBuyPreset = () => {
    navigate(paths.singleBuyNft.replace(':type', TYPE_1ST.Preset));
  };

  const handlerBuySinglePreset = (id: number) => {
    navigate(paths.detailBuyNft.replace(':id', id.toString()));
  };

  const getClass = (index: number) => {
    const CLASS_SUB = 'preset__detail__sub';
    const CLASS_MAIN = 'preset__detail__main';
    if (data.items.length === LIMIT) return (index + 1) % 2 === 0 ? CLASS_MAIN : CLASS_SUB;
    if (data.items.length === 1) return CLASS_MAIN;

    return CLASS_SUB;
  };

  const getStyles = useMemo(() => {
    return {
      '--padding': `${(LIMIT - data.items.length) * 12}%`,
      '--grid-template-columns':
        data.items.length === LIMIT
          ? '1fr 1.18fr 1fr'
          : Array.from(new Array(data.items.length))
              .map((_) => '1fr')
              .join(' '),
    } as any;
  }, [data]);

  return (
    <div className="recommend-preset">
      <h1 className="section-title">{t('home.title.recommendPreset')}</h1>

      {data.items.length ? (
        <div className="recommend-preset-container" style={{ ...getStyles }}>
          {data.items.map((item, index) => (
            <div
              className="preset"
              onClick={handlerBuySinglePreset.bind(null, item.id)}
              key={`preset-recommend${item.id}`}
            >
              <div className={`preset__detail ${getClass(index)}`}>
                <img src={item.previewImg} alt="preset logo" className="logo" />
                <p className="price">
                  {formatNumber(lang === LANGUAGE.JAPAN ? item.price : item.priceUsd, lang)}(
                  {t('Payment.includeTax')})
                </p>
                <Tooltip title={item.weaponsName?.replaceAll(',', '/')}>
                  <p className="weapons-name">{item.weaponsName?.replaceAll(',', '/')}</p>
                </Tooltip>
              </div>
              <Tooltip title={item.name}>
                <p className="preset__name">{item.name}</p>
              </Tooltip>
              <Tooltip title={item.description}>
                <p className="preset__description">{item.description}</p>
              </Tooltip>
            </div>
          ))}
        </div>
      ) : (
        <NotNft title={t('buyNft.list.notFoundTitle')} />
      )}

      <button className="btn btn--light-green" onClick={handlerGotoBuyPreset}>
        {t('home.button.goToPurchase')} &rarr;
      </button>
    </div>
  );
};

export default RecommendPreset;
