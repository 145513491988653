import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TYPE_1ST } from 'constants/common';
import { FirstNftBuy } from 'models/first-nft';

export type BuyNftDto = {
  id: number;
  amount: number;
  rawData: FirstNftBuy;
};

export type BuyNftState = {
  [key in TYPE_1ST]: BuyNftDto[];
};

const initialState: BuyNftState = {
  [TYPE_1ST.Body]: [],
  [TYPE_1ST.Preset]: [],
  [TYPE_1ST.Weapon]: [],
  [TYPE_1ST.All]: [],
  [TYPE_1ST.Illustration]: [],
};

export const BuyNftSlice = createSlice({
  name: 'buyNft',
  initialState: initialState,
  reducers: {
    selectWeaponPresetCustomize: (state, { payload }: PayloadAction<FirstNftBuy>) => {
      const stateSelected: BuyNftDto[] = state[payload.firstNftType];
      let otherType;
      const newData = { id: payload.id, amount: 1, rawData: payload };

      if (payload.firstNftType === TYPE_1ST.Preset) {
        otherType = TYPE_1ST.Weapon;
        state[payload.firstNftType] = [newData];
      } else {
        const weaponPositionIndex = stateSelected.findIndex(
          (item) => item.rawData.positionId === newData.rawData.positionId
        );
        otherType = TYPE_1ST.Preset;

        if (weaponPositionIndex >= 0) {
          stateSelected.splice(weaponPositionIndex, 1, newData);
        } else {
          stateSelected.push(newData);
        }
      }

      state[otherType] = [];
    },
    selectNFT: (state, { payload }: PayloadAction<FirstNftBuy>) => {
      const stateSelected: BuyNftDto[] = state[payload.firstNftType];
      const newData = { id: payload.id, amount: 1, rawData: payload };
      const checkNftDuplicate = stateSelected.find(item => item.id === payload.id)
      if (checkNftDuplicate) return
      stateSelected.push(newData);
    },
    selectOnlyNFT: (state, { payload }: PayloadAction<FirstNftBuy>) => {
      const newNfts: BuyNftDto[] = state[payload.firstNftType];
      const newData = { id: payload.id, amount: 1, rawData: payload };
      const index = newNfts.findIndex((newNft) => newNft.id === newData.id)
      if (index < 0) {
        newNfts.push(newData);
      }
      else {
        const newDataChange = { ...newNfts[index], amount: newNfts[index].amount + 1 };
        newNfts.splice(index, 1, newDataChange)
      }

    },
    increaseNftAmount: (state, { payload }: PayloadAction<FirstNftBuy>) => {
      const stateSelected: BuyNftDto[] = state[payload.firstNftType];
      const newData = { id: payload.id, amount: 1, rawData: payload };

      const weaponPresetSelectId = stateSelected.findIndex((item) => item.id === payload.id);

      if (weaponPresetSelectId >= 0) {
        const current = stateSelected[weaponPresetSelectId];
        current.amount += current.amount >= current.rawData.quantity ? 0 : 1;
      } else {
        stateSelected.push(newData);
      }
    },
    updateNftAmount: (state, { payload }: PayloadAction<{ amount: number; data: FirstNftBuy }>) => {      
      const nft = payload.data;
      const stateSelected: BuyNftDto[] = state[nft.firstNftType];

      const weaponPresetSelectId = stateSelected.findIndex((item) => item.id === nft.id);

      if (weaponPresetSelectId >= 0) {
        stateSelected[weaponPresetSelectId].amount = payload?.amount;
      }
    },

    removeNft: (state, { payload }: PayloadAction<FirstNftBuy>) => {
      state[payload.firstNftType] = state[payload.firstNftType].filter(
        (item) => item.id !== payload.id
      );
    },
    removeAllWeaponPresetNft: (state) => ({ ...initialState, [TYPE_1ST.Body]: state.body }),
    removeAllNft: (state) => {
      return initialState;
    },
    // removeAllNft: (state) => initialState,
  },
});

export const {
  selectNFT,
  selectOnlyNFT,
  selectWeaponPresetCustomize,
  removeNft,
  removeAllWeaponPresetNft,
  increaseNftAmount,
  updateNftAmount,
  removeAllNft,
} = BuyNftSlice.actions;

export default BuyNftSlice.reducer;
