/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { get, isEmpty } from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';

const Model3dContext = createContext({});

export type Model3DStatus = {
  index: number;
  pause: boolean;
  duration: number;
};

let inteval;

export const Model3dContextProvider = (props): any => {
  const [background, setBackground] = useState('');
  const [currentDuration, setCurrentDuration] = useState(0);
  const [modelStatus, setModelStatus] = useState<Model3DStatus>({} as Model3DStatus);
  const [animations, setAnimations] = useState<any>({});
  const [animationNames, setAnimationNames] = useState<string[]>([]);

  useEffect(() => {
    const modelIndex = modelStatus?.index;
    if (modelIndex || modelIndex === 0) {
      const currentAnimation = animations[animationNames[modelIndex]];

      currentAnimation.reset().fadeIn(0.5).play();

      return () => {
        currentAnimation.fadeOut(0.5);
        setCurrentDuration(0);
      };
    }
  }, [modelStatus?.index]);

  useEffect(() => {
    if (!isEmpty(modelStatus)) {
      if (!modelStatus.pause) {
        inteval = setInterval(() => {
          setCurrentDuration((pre) => (pre < modelStatus.duration! ? pre + 0.1 : 0));
        }, 100);
      }

      return () => {
        clearInterval(inteval);
      };
    }
  }, [modelStatus?.pause]);

  const loadAnimation = (animations: any, names: string[]) => {
    setAnimations(animations);
    setAnimationNames(names);
  };

  const playAnimation = (index?: number) => {
    console.log("playAnimation")
    if (index || index === 0) {
      const currentAnimation = animations[animationNames[index]];
      const animationDuration = Math.round(get(currentAnimation, '_clip.duration', 0));

      if (!animationDuration) {
        clearTimeout(inteval);
      }

      setModelStatus({ index, pause: !animationDuration, duration: animationDuration });
    } else {
      animations[animationNames[modelStatus?.index]].paused = false;
      setModelStatus((pre) => ({ ...pre, pause: false }));
    }
  };

  const stopAnimation = () => {
    animations[animationNames[modelStatus?.index]].paused = true;
    setModelStatus((pre) => ({ ...pre, pause: true }));
  };

  const onCaptureImage = () => {
    const canvas = document.getElementsByTagName('canvas');
    return canvas[0].toDataURL('image/png').replace('image/png', 'image/octet-stream');
  };

  return (
    <Model3dContext.Provider
      value={{
        setBackground,
        loadAnimation,

        modelStatus,
        animationNames,
        currentDuration,
        background,

        playAnimation,
        stopAnimation,

        onCaptureImage,
      }}
    >
      {props.children}
    </Model3dContext.Provider>
  );
};

export const useModel3dContext = () => {
  return useContext(Model3dContext);
};
