import React from 'react';
import ReactLoading from 'react-loading';
const LoadingUpbond = () => {
  return (
    <>
      <ReactLoading type="spinningBubbles" color="#18c4af" width={40} height={40} />
    </>
  );
};

export default LoadingUpbond;
