import { notification } from "antd";
import { API } from "utils/api/api-route";
import ApiUtils from "utils/api/api.utils";

const getArtistNfts = async (params) => {
  try {
    const response: any = await ApiUtils.fetch(
      API.nft,
      params
    );
    return response;
  } catch (error) {
    notification.error({
      message: 'Error',
      description: error?.message,
      duration: 4,
    });;
  }
}

const getNftDetail = async (id:number) => {
  try {
    const response: any = await ApiUtils.fetch(
      `${API.nft}/${id}`
    );
    return response;
  } catch (error) {
    notification.error({
      message: 'Error',
      description: error?.message,
      duration: 4,
    });;
  }
}

const likeNft = async (nftId: string | number) => {
  try {
    const response: any = await ApiUtils.post(
      `${API.likeNft}`,
      {nftId}
    );
    return response;
  } catch (error) {
    notification.error({
      message: 'Error',
      description: error?.message,
      duration: 4,
    });;
  }
}

export {getArtistNfts, likeNft, getNftDetail}