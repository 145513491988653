import { ThemeContext } from 'ThemeProvider';
import { Checkbox, ConfigProvider, Modal } from 'antd';
import 'antd/dist/reset.css';
import GlobalLoading from 'components/global-loading';
import NotFound from 'components/not-found';
import MainLayout from 'layouts/index';
import { RouteObject } from 'models/route';
import { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import PrivateRoutes from 'routes/private-route';
import { mainRoutes, paths } from 'routes/routes';
import { RootState } from 'stores/store';
import {
  loadingSystem,
  loadingSystemSuccess,
  updateLocationSystem,
} from 'stores/system/systemSlice';
import { checkToken } from 'utils';
import { getUpbondToken, getUserInfo } from 'utils/auth/auth.utils';
import socket from 'utils/socket/socket';
import './App.scss';
import localStorageHelper, { KeyStorage } from 'utils/localStorage';
import ApiUtils from 'utils/api/api.utils';
import { API } from 'utils/api/api-route';
import { ContractInformation } from 'types/contract';
import MasterServices from 'services/master.service';
import { useTranslation } from 'react-i18next';
import upbondServices from 'hooks/upbond-embed';
import ReactGA from 'react-ga4';
import i18next from "i18next";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import {configOptions} from "./constants/cookieConsent";

function App() {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const isLogin = useSelector((state: RootState) => state.user.isLogin);
  const location = useLocation();
  const userInfo = getUserInfo();
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [isAcceptPolicyTerm, setIsAcceptPolicyTerm] = useState<boolean>(true);
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const getContract = useCallback(async () => {
    const contract = (await ApiUtils.fetch(API.contracts)) as { data: ContractInformation };
    try {
      if (contract) {
        localStorageHelper.setObject(KeyStorage.CONTRACT, contract);
      } else {
        console.log('error getContract');
      }
    } catch (error) {
      //
    }
  }, []);

  const handleVerify = async () => {
    const isVerify = await MasterServices.verifyWallet();
    setIsAcceptPolicyTerm(isVerify.acceptPolicyTerm);
  };

  const handleSubmit = async () => {
    const res: any = await MasterServices.submitTerm();
    if (res.isSuccess) {
      handleVerify();
    }
  };

  const onClickLogout = async () => {
    dispatch(loadingSystem());
    await upbondServices.logout();
    dispatch(loadingSystemSuccess());
    navigate(paths.login);
    window.location.reload();
  };

  const isShowModal = location?.pathname !== '/login' && !isAcceptPolicyTerm;

  useEffect(() => {
    document.documentElement.setAttribute('lang', i18n.language);
    localStorageHelper.remove(KeyStorage.CONTRACT);
    const contract = localStorageHelper.getObject(KeyStorage.CONTRACT);
    if (!contract) {
      getContract();
    }

    // GDPR
    CookieConsent.run(configOptions);
  }, []);

  useEffect(() => {
    if (location?.pathname !== '/login') {
      const router = location?.pathname + location?.search;
      dispatch(updateLocationSystem(router));
    }

    const pageView = location.pathname + location.search
    ReactGA.send({ hitType: "pageview", page: pageView, title: pageView });
  }, [location?.pathname, location?.search, dispatch]);

  useEffect(() => {
    userInfo?.address && handleVerify();
  }, [userInfo]);

  useEffect(() => {
    const token = checkToken();
    if (isLogin && token) {
      const query = socket.io.opts.query as any;
      if (!query.account) {
        const account = getUpbondToken().trim().split(' ')[0];
        socket.io.opts.query = {
          account,
        };
        socket.disconnect().connect();
      }
    }

    if (!isLogin) {
      socket.io.opts.query = {};
      socket.disconnect().connect();
    }
  }, [isLogin]);
  i18next.on('languageChanged', (lng) => {
    document.documentElement.setAttribute('lang', lng);
  })

  return (
    // <ThemeProvider>
    <div className="App" id={`${theme}`}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#18C3AF',
            colorTextBase: '#000000',
            fontFamily: 'Manrope',
          },
          // components:{}
        }}
      >
        <Suspense fallback={<GlobalLoading isOpen={true} />}>
          <Routes>
            {mainRoutes.map((route: RouteObject, index: number) => {
              const Component = route.component;
              if (route.isPublish) {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <MainLayout>
                        <Component />
                      </MainLayout>
                    }
                  />
                );
              }
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <PrivateRoutes>
                      <MainLayout>
                        <Component />
                      </MainLayout>
                    </PrivateRoutes>
                  }
                />
              );
            })}
            <Route key="not-found-page" path={paths.notFound} element={<NotFound />} />
          </Routes>
        </Suspense>
        <Modal
          forceRender
          open={isShowModal}
          width={700}
          getContainer={false}
          footer={false}
          onCancel={onClickLogout}
        >
          <div>
            <Checkbox
              onChange={(e) => {
                setIsAgree(e.target.checked);
              }}
            ></Checkbox>
            {i18n.language === 'en' ? (
              <span style={{ marginLeft: 10 }}>
                I agree to the
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://upbond.notion.site/UPBOND-Wallet-Terms-of-Service-1b6d7c8f72a94f799c8ab1b82df6c799"
                >
                  terms of use
                </a>
              </span>
            ) : (
              <span style={{ marginLeft: 10 }}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://upbond.notion.site/UPBOND-Wallet-Terms-of-Service-1b6d7c8f72a94f799c8ab1b82df6c799"
                >
                  利用規約
                </a>
                に同意します
              </span>
            )}
          </div>
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn--connectWallet" disabled={!isAgree} onClick={handleSubmit}>
              OK
            </button>
          </div>
        </Modal>
      </ConfigProvider>
    </div>
    // </ThemeProvider>
  );
}

export default App;
