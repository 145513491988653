import { CameraOutlined, CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { Button, Col, Image, Progress, Row, Slider, Space } from 'antd';
import { get, isEmpty } from 'lodash';
import { useState } from 'react';
import { useModel3dContext } from '../context';

const backgrounds = [
  'https://p16-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/62dbae46b58870981f06d84063b0d85a.jpeg?x-expires=1678899600&x-signature=mwhPv3g%2BOooxRTHnpc3uJJU%2B3so%3D',
  'https://t3.ftcdn.net/jpg/01/08/08/58/360_F_108085811_ssVzwb50KvRrpvydbke92qnN0dxWBPXu.jpg',
  'https://images.unsplash.com/photo-1588421357574-87938a86fa28?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80',
];

const ControlPanel = () => {
  const { modelStatus, playAnimation, stopAnimation, currentDuration }: any = useModel3dContext();

  return (
    <div>
      {!isEmpty(modelStatus) && (
        <>
          <div className="d-flex justify-between">
            {modelStatus.pause ? (
              <CaretRightOutlined onClick={() => playAnimation()} />
            ) : (
              <PauseOutlined onClick={stopAnimation} />
            )}
            <p>
              0:{currentDuration}/0:{modelStatus.duration}
            </p>
          </div>
          <Progress percent={(currentDuration / get(modelStatus, 'duration', 0)) * 100} />
        </>
      )}
    </div>
  );
};

const Control = () => {
  const [images, setImages] = useState<string[]>([]);
  const { animationNames, modelStatus, playAnimation, onCaptureImage, setBackground }: any =
    useModel3dContext();

  const handlerCaptureImage = () => {
    const url = onCaptureImage();
    setImages((pre) => [...pre, url]);
  };
  return (
    <>
      <h1>background</h1>
      <Space>
        {backgrounds.map((item) => (
          <Image src={item} onClick={() => setBackground(item)} preview={false} />
        ))}
      </Space>

      <h1>action</h1>
      <Space>
        {animationNames.map((item, index) => (
          <Button
            key={'animationNames-' + index}
            type={modelStatus?.index === index ? 'primary' : 'default'}
            onClick={playAnimation.bind(null, index)}
          >
            {item}
          </Button>
        ))}
      </Space>

      <h1>Control</h1>
      <ControlPanel />
      <Button onClick={handlerCaptureImage}>
        <CameraOutlined />
      </Button>

      <h1>Capture image</h1>
      <Row gutter={[16, 16]}>
        {images.map((img) => (
          <Col span="6">
            <Image sizes="100%" src={img} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Control;
