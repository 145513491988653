import Upbond, { BuildEnv, LoginConfig, LoginConfigItem } from "@upbond/upbond-embed";
import { notification } from "antd";
import { UPBOND_ENV } from "constants/envs";
import { ethers } from "ethers";
import { checkToken } from "utils";
import { API } from "utils/api/api-route";
import ApiUtils from "utils/api/api.utils";
import { saveUpbondToken } from "utils/auth/auth.utils";
import localStorageHelper from "utils/localStorage";
import Web3 from "web3";
import Web3Token from "web3-token";
import * as Sentry from '@sentry/react'

export const defaultLoginParam = {
    "upbond-line": {
        name: "LINE",
        description: "LINE",
        typeOfLogin: "line",
        loginProvider: "upbond-line",
        verifier: process.env.REACT_APP_LINE_VERIFIER,
        jwtParameters: {
            domain: process.env.REACT_APP_LOGIN_UPBOND_DOMAIN,
            connection: "line",
            clientId: process.env.REACT_APP_LINE_CLIENT_ID,
            scope: "openid email profile offline_access",
        },
        clientId: process.env.REACT_APP_LINE_CLIENT_ID,
        showOnModal: true,
        showOnDesktop: true,
        showOnMobile: true,
        mainOption: true,
        priority: 1,
        customLogo: "line",
        logo: "https://elvira.co.th/wp-content/uploads/2016/02/line-icon.png",
        buttonBgColor: "#289B2A",
        buttonTextColor: "#f3f3f3",
    } as LoginConfigItem,
    "upbond-google": {
        name: "Google",
        description: "Google",
        typeOfLogin: "jwt",
        loginProvider: "upbond-google",
        verifier: process.env.REACT_APP_GOOGLE_VERIFIER,
        jwtParameters: {
            domain: process.env.REACT_APP_LOGIN_UPBOND_DOMAIN,
            connection: "google",
            clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        },
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        showOnModal: true,
        showOnDesktop: true,
        showOnMobile: true,
        mainOption: true,
        priority: 2,
        customLogo: "google",
        logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRu1PJmT_THldF0n5APcmt9p10utgu6KSw4cH2fQ5Xhpw&s",
        buttonBgColor: "#4B68AE",
        buttonTextColor: "#FFF",
    } as LoginConfigItem,
} as LoginConfig;

class UpbondEmbed {
    // Initials
    upbond: any = null;
    web3: any = null;
    // you can also use another envs.
    env = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development'; // may be development | staging | production

    provider: any;

    isLoggedIn = false;

    initialized = false;

    constructor() {
        this.upbond = new Upbond({});
        this.web3 = new Web3();
        this.provider = null;
    }

    async init(language) {

        if (this.upbond instanceof Upbond) {
            await this.upbond.init({
                buildEnv: UPBOND_ENV as BuildEnv,
                dappRedirectUri: window.location.origin,
                isUsingDirect: true,
                enableLogging: true,
                whiteLabel: {
                    walletTheme: {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore: Unreachable code error
                        lang: language,
                        logo: `${window.location.origin}/logo.png`,
                        name: "DTA",
                        buttonLogo:
                            "https://line-imagemap.s3.ap-northeast-1.amazonaws.com/lzg2dndj_66b2e65e6fe438000a7f5dec/lzg2dndj_66b2e65e6fe438000a7f5dec.jpg",
                        isActive: true,
                        modalColor: "#fffff",
                        bgColor: "#4B68AE",
                        bgColorHover: "#214999",
                        textColor: "#f3f3f3",
                        textColorHover: "#214999",
                        upbondLogin: {
                            globalBgColor: "#ffffff",
                            globalTextColor: "#4B68AE",
                        },
                    },
                },
                loginConfig: defaultLoginParam
            });
            console.log('UPBOND Embed initialized!')
            this.initialized = true;
        }
    }

    changeStatusInit() {
        this.initialized = false
    }

    async signWeb3Token(account, callbackNavigateHome) {
        try {
            const ether = new ethers.providers.Web3Provider(this.upbond.provider);
            const signer = await ether.getSigner();
            const sign = await Web3Token.sign(async (msg) => {
                if (this.web3 instanceof Web3) {
                    return await signer.signMessage(msg);
                }
            }, {
                domain: process.env.REACT_APP_DOMAIN_WALLET,
                expires_in: '3 days',
                nonce: 11111111,
            });
            return sign;
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'You rejected signing. You need to sign it to get access!',
                duration: 4,
            })
            callbackNavigateHome()
            return;
        }
    }

    async login(callbackNavigateHome) {

        try {
            if (this.upbond instanceof Upbond && this.web3 instanceof Web3) {
                const _provider = await this.upbond.login(); // login using upbond
                this.web3.setProvider(this.upbond.provider as any);
                const accounts = await this.web3.eth.getAccounts();
                this.isLoggedIn = true;
                this.provider = _provider;
                const token = await this.signWeb3Token(accounts[0], callbackNavigateHome)
                saveUpbondToken(token as string, accounts[0])
                Sentry.setUser({ username: accounts[0] });
                return {
                    msg: "success",
                    data: _provider,
                    accounts,
                    token: token
                    // ... anything that you want to returns
                };
            }
        } catch (error: any) {
            console.log(error, "@errorOnReactProject?");
            if (error?.message === 'User cancelled login') {
                // ログインキャンセルの場合はトーストを炊かない
            } else {
                notification.error({
                    message: 'Error',
                    description: error?.message || 'Some error occurred',
                    duration: 4,
                });
            }
            return {
                msg: error.message || "Failed to login",
                data: null,
            };
        }
    }

    async logout() {
        try {
            if (this.upbond instanceof Upbond) {
                if (checkToken()) {
                    try {
                        await ApiUtils.post(API.logout)
                    } catch (error) {
                        console.log('error', error?.message)
                    }
                }
                localStorageHelper.clear(localStorage);
                Sentry.setUser(null);
                await this.upbond.logout();
                await this.upbond.cleanUp();
                await this.upbond.clearInit();
                return {
                    msg: "success",
                    data: true,
                };
            }
        } catch (error: any) {
            return {
                msg: error.message || "Failed to login",
                data: null,
            };
        }
    }

    async getUserInfo() {
        if (this.upbond instanceof Upbond) {
            try {
                const userInfo = await this.upbond.getUserInfo('');
                return userInfo;
            } catch (error: any) {
                notification.error({
                    message: 'Error',
                    description: 'Some error occurred',
                    duration: 4,
                });
                throw new Error(error);
            }
        }
    }
}

const upbondServices = new UpbondEmbed();

export default upbondServices;
