import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { checkToken } from 'utils';

interface Props {
  children: ReactElement;
}
const PrivateRoutes = ({ children }: Props) => {
  const auth = checkToken();

  if (!auth) return <Navigate to="/login" />;
  return children;
};

export default PrivateRoutes;
