import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'stores/store';

const selectMaster = (state: RootState) => state.master;

export const getCharacters = createSelector(selectMaster, (master) => master.characters);

export const getParts = createSelector(selectMaster, (master) => master.parts);

export const getBackgrounds = createSelector(selectMaster, (master) => master.backgrounds);

export const getCharactersTransfromForSelect = createSelector(getCharacters, (characters) =>
  characters.map((char) => ({
    value: char.id,
    label: char.name,
  }))
);

export const getWeaponPositionTransfromForSelect = createSelector(getParts, (parts) =>
  parts.map((p) => ({
    value: p.id,
    label: p.name,
  }))
);
