export enum NOTIFICATION_TYPE {
  SECOND_NFT_REJECTED = 'rejected',
  SECOND_NFT_APPROVED = 'approved',
  LIKES = 'likes',
}

export interface NotiDetailType {
  id?: number;
  nftId: number;
  toUserId?: number;
  fromUserId?: number;
  fromAdminId?: number;
  fromUserWalletAddress?: string;
  notificationId?: number;
  type: NOTIFICATION_TYPE;
  isRead?: number;
  likeStatus?: number;
  nftName?: string;
  nftImage?: string;
  userImage?: string;
}


