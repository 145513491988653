import { Image, Tooltip } from 'antd';
import clsx from 'clsx';
import { NotiDetailType, NOTIFICATION_TYPE } from 'models/notification';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ArtishDetail, BaseNftDetail, paths } from 'routes/routes';
import { readOneNoti } from 'services/notification-service';
import { shortenName } from 'utils/short-word';
import styles from '../styles.module.scss';
import DefaultAvatar from 'assets/images/avatar-default.png';

interface IProps {
  item: NotiDetailType;
  handleMarkRead: (value: number) => void;
  index: number;
  setOpenNotice: (value: boolean) => void;
}

const NotiDetail = ({ item, handleMarkRead, index, setOpenNotice }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderName = (name: string, id: number | string) => {
    return (
      <span
        onClick={(e) => {
          e.stopPropagation();
          handleReadNoti();
          navigate(`${ArtishDetail}/${id}`);
          setOpenNotice(false);
        }}
      >
        <Tooltip title={name}>
          <span style={{ color: '#18c4af' }}>{shortenName(name, 10) || ''}</span>
        </Tooltip>
      </span>
    );
  };

  const renderNft = (nft: string, id: number | string) => {
    return (
      <span
        onClick={(e) => {
          e.stopPropagation();
          handleReadNoti();
          navigate(`${BaseNftDetail}/${id}`);
          setOpenNotice(false);
        }}
      >
        <Tooltip title={nft}>
          <span style={{ color: '#18c4af' }}>{shortenName(nft, 10) || ''}</span>
        </Tooltip>
      </span>
    );
  };

  const renderContent = useCallback(() => {
    switch (item.type) {
      case NOTIFICATION_TYPE.SECOND_NFT_APPROVED:
        return (
          <div
            className={clsx(styles.boxAlertNotice, !item?.isRead && styles.boxAlertNoticeRead)}
            onClick={() => {
              handleReadNoti();
              setOpenNotice(false);
              navigate(`${BaseNftDetail}/${item.nftId}`);
            }}
          >
            <div className={styles.boxContent}>
              {item?.nftImage && (
                <Image preview={false} src={item?.nftImage} width={30} height={30} />
              )}
              <div className={styles.boxDescription}>
                {renderNft(item.nftName || '', item.nftId || '')}

                <span> {t('notifications.approve')} </span>
              </div>
            </div>
          </div>
        );

      case NOTIFICATION_TYPE.SECOND_NFT_REJECTED:
        return (
          <div
            className={clsx(styles.boxAlertNotice, !item?.isRead && styles.boxAlertNoticeRead)}
            onClick={() => {
              handleReadNoti();
              setOpenNotice(false);
              navigate(paths.myPage, { state: { menu: 12, nftId: item.nftId } });
            }}
          >
            <div className={styles.boxContent}>
              {item?.nftImage && (
                <Image preview={false} src={item?.nftImage} width={30} height={30} />
              )}
              <div className={styles.boxDescription}>
                <span>
                  <Tooltip title={item.nftName}>
                    <span style={{ color: '#18c4af' }}>{shortenName(item.nftName, 10) || ''}</span>
                  </Tooltip>
                </span>
                <span> {t('notifications.rejected')} </span>
              </div>
            </div>
          </div>
        );

      case NOTIFICATION_TYPE.LIKES:
        return (
          <div
            className={clsx(styles.boxAlertNotice, !item?.isRead && styles.boxAlertNoticeRead)}
            onClick={() => {
              setOpenNotice(false);
              handleReadNoti();
              navigate(`${BaseNftDetail}/${item.nftId}`);
            }}
          >
            <div className={styles.boxContent}>
              <Image
                preview={false}
                src={item?.userImage || DefaultAvatar}
                width={30}
                height={30}
                onClick={(e) => {
                  e.stopPropagation();
                  handleReadNoti();
                  setOpenNotice(false);
                  navigate(`${ArtishDetail}/${item.fromUserId}`);
                }}
              />
              <div className={styles.boxDescription}>
                {renderName(item.fromUserWalletAddress || '', item.fromUserId || '')}
                <span> {t('notifications.likeYour')} </span>
                {renderNft(item.nftName || '', item.nftId || '')}
                <span> {t('notifications.likeEnd')} </span>
              </div>
            </div>
          </div>
        );
      default:
        return '';
    }
  }, [item]);

  const handleReadNoti = async () => {
    if (!item?.isRead && item?.notificationId) {
      const result = await readOneNoti(Number(item?.notificationId));
      result && handleMarkRead(index);
    }
  };

  return <>{renderContent()}</>;
};

export default NotiDetail;
