

import { NftImagesRes } from 'models/common';
import { NFT_IMAGE_TYPE } from 'models/nft';
import { useMemo } from 'react';

const useGetImageDetailNft = (arrImages: Array<NftImagesRes> = [], type: NFT_IMAGE_TYPE) => {
    const getImageNft = useMemo(() => {
        if (arrImages?.length < 1) return;
        const image = arrImages.find((item) => item.type === type);
        return image;

    }, [arrImages, type]);

    return getImageNft;
};

export default useGetImageDetailNft;
