//https://cookieconsent.orestbida.com/essential/getting-started.html#configuration

import * as CookieConsent from "vanilla-cookieconsent";
import {
    ConsentModalLayout,
    ConsentModalPosition,
    PreferencesModalLayout,
    PreferencesModalPosition
} from "vanilla-cookieconsent";

export const configOptions = {
    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
    },
    guiOptions: {
        consentModal: {
            layout: 'bar' as ConsentModalLayout,
            position: 'bottom' as ConsentModalPosition,
            flipButtons: false,
            equalWeightButtons: true
        },
        preferencesModal: {
            layout: 'bar' as PreferencesModalLayout,
            position: 'right' as PreferencesModalPosition,
            flipButtons: false,
            equalWeightButtons: true
        }
    },
    language: {
        default : 'ja',
        translations: {
            en: {
                consentModal: {
                    // title: 'We use cookies',
                    description: `
                    本サイトは必要なサイト機能を提供するために Cookie を使用します。 本サイトをご利用いただく中でクッキーを利用してコンテンツや広告をパーソナライズする場合があります。 本サイトのプライバシーポリシー、およびサービス利用規約に同意する場合、これらの動作が有効化されます。
                    `,
                    acceptAllBtn: '同意する',
                    acceptNecessaryBtn: '拒否する',
                    showPreferencesBtn: 'Cookie使用許可の管理'
                },
                preferencesModal: {
                    title: 'Cookie使用許可の管理',
                    acceptAllBtn: 'すべて同意する',
                    acceptNecessaryBtn: 'すべて拒否する',
                    savePreferencesBtn: '現在の設定を保存',
                    closeIconLabel: '閉じる',
                    sections: [
                        {
                            title: '一般情報',
                            description: '本ウェブサイトでは、「必須 Cookie」、「機能性 Cookie」、「広告 Cookie」という 3 種類の Cookie を定義しています。機能性 Cookie と広告 Cookie については、無効化することができます。各 Cookie の詳細を確認し、デフォルトの設定を変更する場合は、該当する項目をクリックしてください。'
                        },
                        {
                            title: '必須 Cookie',
                            description: '必須 Cookie は、ウェブサイトの基本的な運用に必要不可欠な Cookie です。例えば、ウェブサイト上の情報を送信するのに必要なセッション Cookieや、認証 Cookie、セキュリティ Cookie などがあります。',

                            //this field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary'
                        },
                        {
                            title: '機能性 Cookie',
                            description: '機能性 Cookie は、ウェブサイトの機能、パフォーマンス、サービスを向上するために使用するCookie です。例えば、サイトへのアクセス数解析に使用するCookie、市場調査に使用する Cookie、不特定多数のユーザーに広告を表示するためのCookie などがあります。現在、本ウェブサイトでこれらのCookieは利用しておりません。',
                            linkedCategory: 'functional'
                        },
                        {
                            title: '広告 Cookie',
                            description: '広告 Cookie は、ユーザーの関心分野を把握するためにウェブサイト上での活動を追跡し、ターゲットを絞って特定の広告を表示するための Cookie です。例えば、リマーケティングやターゲティング広告に使用する Cookie などがあります。現在、本ウェブサイトでこれらのCookieは利用しておりません。',
                            linkedCategory: 'advertisement'
                        }
                    ]
                }
            },
            ja: {
                consentModal: {
                    // title: 'We use cookies',
                    description: `
                    本サイトは必要なサイト機能を提供するために Cookie を使用します。 本サイトをご利用いただく中でクッキーを利用してコンテンツや広告をパーソナライズする場合があります。 本サイトのプライバシーポリシー、およびサービス利用規約に同意する場合、これらの動作が有効化されます。
                    `,
                    acceptAllBtn: '同意する',
                    acceptNecessaryBtn: '拒否する',
                    showPreferencesBtn: 'Cookie使用許可の管理'
                },
                preferencesModal: {
                    title: 'Cookie使用許可の管理',
                    acceptAllBtn: 'すべて同意する',
                    acceptNecessaryBtn: 'すべて拒否する',
                    savePreferencesBtn: '現在の設定を保存',
                    closeIconLabel: '閉じる',
                    sections: [
                        {
                            title: '一般情報',
                            description: '本ウェブサイトでは、「必須 Cookie」、「機能性 Cookie」、「広告 Cookie」という 3 種類の Cookie を定義しています。機能性 Cookie と広告 Cookie については、無効化することができます。各 Cookie の詳細を確認し、デフォルトの設定を変更する場合は、該当する項目をクリックしてください。'
                        },
                        {
                            title: '必須 Cookie',
                            description: '必須 Cookie は、ウェブサイトの基本的な運用に必要不可欠な Cookie です。例えば、ウェブサイト上の情報を送信するのに必要なセッション Cookieや、認証 Cookie、セキュリティ Cookie などがあります。',

                            //this field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary'
                        },
                        {
                            title: '機能性 Cookie',
                            description: '機能性 Cookie は、ウェブサイトの機能、パフォーマンス、サービスを向上するために使用するCookie です。例えば、サイトへのアクセス数解析に使用するCookie、市場調査に使用する Cookie、不特定多数のユーザーに広告を表示するためのCookie などがあります。現在、本ウェブサイトでこれらのCookieは利用しておりません。',
                            linkedCategory: 'functional'
                        },
                        {
                            title: '広告 Cookie',
                            description: '広告 Cookie は、ユーザーの関心分野を把握するためにウェブサイト上での活動を追跡し、ターゲットを絞って特定の広告を表示するための Cookie です。例えば、リマーケティングやターゲティング広告に使用する Cookie などがあります。現在、本ウェブサイトでこれらのCookieは利用しておりません。',
                            linkedCategory: 'advertisement'
                        }
                    ]
                }
            }
        }
    }
}