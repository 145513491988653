import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  isLogin: boolean;
}

const initialState: UserState = {
  isLogin: false,
};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoginState(state: UserState, action: PayloadAction<boolean>) {
      state.isLogin = action.payload;
    },
  },
});

export const { setLoginState } = UserSlice.actions;

export default UserSlice.reducer;
