import { TYPE_1ST, TYPE_2ND } from 'constants/common';
import { NFT_IMAGE_TYPE } from './nft';

export interface PaginationParams {
  currentPage: string | number;
  itemPage: number;
  lastPage: number;
  total: number;
}

export interface ListResponse<T> {
  items: T[];
  totalCount: number;
}

export interface UserInfo {
  token: string | null | undefined;
  expirationTime: number | null;
  id: string | number;
  address: string;
  email?: string;
  acceptPolicyTerm?: boolean;
}

export interface UserProfile {
  userName: string;
  aboutMe: string;
  avatarImg: string;
  previewAvatar2DObjectKey: string;
  previewAvatar2DStatus: string;
  coverImg: string;
  previewCover2DObjectKey: string;
  previewCover2DStatus: string;
  walletAddress: string;
}


export enum IUploadType {
  CIRCLE = 'CIRCLE',
  RETANGLE = 'RETANGLE',
}

export enum UPLOAD_TYPE {
  IMAGE = 'image',
  MODEL = 'model',
  PREVIEW2D = 'preview2d',
  PREVIEW3D = 'preview3d',
  ILLUSTRATION = "illustration",
  ILLUSTRATION_DATA = "illustrationData",
}
export interface LikeRes {
  totalLikes: number;
  isLiked: boolean;
}

export interface NftImagesRes {
  id: number | string;
  name: string;
  status: string;
  type: NFT_IMAGE_TYPE;
  url?: string;
  objectKey?: string
}

export interface NftWeaponRes {
  id: number | string;
  description: string;
  positionId: number;
  preview2DObjectKey: string;
  preview2DStatus: string;
  title: string
}

export enum FILTER_MY_PAGE {
  ALL = 'all',
  BODY = 'body',
  PRESET = 'preset',
  WEAPON = 'weapon',
  FIRST_NFT = 'firstNft',
  SECOND_NFT = 'secondNft',
  TRADED_NFT = 'tradedNft'
}


export type GetCharacterRequestParams = {
  page: number;
  limit: number;
  desc?: boolean;
  sortField?: string;
  name?: string;
};

export type GetPositionRequestParams = {
  page: number;
  limit: number;
  desc?: boolean;
  sortField?: string;
};

export interface ResPresign {
  url: string;
  path: string;
  userId: number | string;
  imageId: number | string
  headers: any
}

export interface Create2ndNftPresign extends ResPresign {
  nftId: number
}

export interface FILTER_NFT1ST {
  type: TYPE_1ST
}

export interface FILTER_NFT2ND {
  type: TYPE_2ND;

}


