import {BellFilled, CopyOutlined, DownOutlined, MenuOutlined} from '@ant-design/icons';
import {Drawer, Dropdown, Menu, MenuProps, Modal, Select, Space, notification} from 'antd';
import IconLogout from 'assets/images/icons/icon_logout.svg';
import logo from 'assets/images/logo.png';
import {LANGUAGE, TYPE_1ST} from 'constants/common';
import upbondServices from 'hooks/upbond-embed';
import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {NavLink, useNavigate} from 'react-router-dom';
import {paths} from 'routes/routes';
import {
    changeLanguageAction,
    loadingSystem,
    loadingSystemSuccess,
} from 'stores/system/systemSlice';
import {setLoginState} from 'stores/user/userSlice';
import {checkToken} from 'utils';
import {getUserInfo} from 'utils/auth/auth.utils';
import {ellipsisMiddle} from 'utils/short-word';
import ListNoti from './components/list-noti';
import styles from './styles.module.scss';
import './styles.scss';
import {TransactionStatistic} from "../../models/transaction";
import ApiUtils from "../../utils/api/api.utils";
import {API} from "../../utils/api/api-route";
import Close from "../../features/upload-2nd-nft/assets/close";

interface Props {
    account: string;
}

const AppHeader = ({account}: Props) => {
    const {i18n, t} = useTranslation();
    const [openNav, setOpenNav] = useState(false);
    const [openNotice, setOpenNotice] = useState(false);
    const [ishaveNoti, setIsHaveNoti] = useState<boolean>(false);

    const userInfo = getUserInfo();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const { pathname }: { pathname: string } = useLocation();

    // const is2NDNftSite = pathname.includes(Base2ndNftPath);
    // const is2NDNftUpload = pathname.includes(paths.upload2ndNft3D);

    const upbond = upbondServices.upbond;

    useEffect(() => {
        if (account && checkToken()) {
            dispatch(setLoginState(true));
        } else {
            dispatch(setLoginState(false));
        }
    }, [dispatch, account]);


    const itemsHeader = useMemo(() => {
        // const menu2nd = [
        //   { label: t('Header.menu.top'), key: paths.home },
        //   { label: t('Header.menu.ranking'), key: paths.nft2ndRanking },
        //   { label: t('Header.menu.myPage'), key: paths.myPage },
        // ];

        // if (is2NDNftUpload) {
        //   return menu2nd;
        // }

        // if (is2NDNftSite) {
        //   return menu2nd.filter((item) => item.key !== paths.nft2ndRanking);
        // }

        return [
            {label: t('Header.menu.notice'), key: paths.notifications},
            {
                label: t('Header.menu.purchase'),
                key: paths.detailBuyNft,
                children: [
                    {
                        label: t('Header.menu.purchase-illustration'),
                        key: paths.singleBuyIllustration,
                    },
                    {
                        label: t('Header.menu.purchase-body'),
                        key: paths.singleBuyNft.replace(':type', TYPE_1ST.Body),
                    },
                    {
                        label: t('Header.menu.purchase-weapon'),
                        key: paths.singleBuyNft.replace(':type', TYPE_1ST.Weapon),
                    },
                    {
                        label: t('Header.menu.purchase-preset'),
                        key: paths.singleBuyNft.replace(':type', TYPE_1ST.Preset),
                    },
                ],
            },
            {label: t('Header.menu.myPage'), key: paths.myPage},
            {label: t('Header.menu.create2nd'), key: paths.nft2ndTop},
        ];
    }, [t]);

    const handleChangeLanguage = (value: string) => {
        upbond.clearInit();
        i18n.changeLanguage(value);
        dispatch(changeLanguageAction(value));
        upbondServices.changeStatusInit();
    };
    const onClickConnectWallet = () => {
        setOpenNav(false);
        navigate(paths.login);
    };

    const handleLogout = useCallback(async () => {
        dispatch(loadingSystem());
        await upbondServices.logout();
        dispatch(loadingSystemSuccess());
        dispatch(setLoginState(false));
        navigate(paths.login);
        window.location.reload();
    }, [dispatch, navigate]);

    const handleHaveNoti = (isHave: boolean) => {
        setIsHaveNoti(isHave);
    };

    const renderNoti = () => {
        return (
            <Modal
                open={openNotice}
                onCancel={() => setOpenNotice(false)}
                closable={false}
                footer={false}
                mask={false}
                width={300}
                style={{top: 70, right: isMobile ? 30 : 100, position: 'absolute'}}
            >
                <ListNoti
                    openNotice={openNotice}
                    handleHaveNoti={handleHaveNoti}
                    setOpenNotice={setOpenNotice}
                />
            </Modal>
        );
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div className="itemDrop" onClick={handleLogout}>
                    <img src={IconLogout} style={{marginRight: 9}} alt=""/>
                    {t('Header.btnLogout')}
                </div>
            ),
        },
    ];

    return (
        <div className={styles.containerHeader}>
            <div className={styles.boxHeaderMobile}>
                <div>
                    <a href="/">
                        <img src={logo} alt=""/>
                    </a>
                </div>
                <div className={styles.menuIcon}>
                    {account && checkToken() && userInfo?.address && (
                        <div>
                            <BellFilled
                                className="alert-icon"
                                style={{
                                    fontSize: 25,
                                    cursor: 'pointer',
                                    marginRight: 20,
                                    marginTop: 5,
                                    color: '#18C3AF',
                                }}
                                onClick={(event: any) => {
                                    setOpenNotice(!openNotice);
                                }}
                            />
                            {ishaveNoti && (
                                <div
                                    style={{
                                        width: 10,
                                        height: 10,
                                        backgroundColor: 'red',
                                        borderRadius: '50%',
                                        position: 'absolute',
                                        top: 35,
                                    }}
                                ></div>
                            )}
                            {renderNoti()}
                        </div>
                    )}
                    <MenuOutlined style={{fontSize: 30}} onClick={() => setOpenNav(true)}/>
                </div>
                <Drawer
                    open={openNav}
                    placement="left"
                    onClose={() => setOpenNav(false)}
                    className="drawer__header"
                >
                    <div className="drawer__header--content">
                        <div>
                            <div>
                                <img src={logo} style={{marginLeft: 22}} alt=""/>
                            </div>
                            <Menu
                                className={styles.menuHeader}
                                style={{borderInlineEnd: 'none', marginTop: 5}}
                                mode="inline"
                            >
                                {itemsHeader.map((item, index) => {
                                    return (
                                        <Fragment key={'mobile-item-header' + index}>
                                            {item.children ? (
                                                <Menu.SubMenu
                                                    key={'sub-mn__title' + index}
                                                    title={
                                                        <>
                                                            <span>{item.label}</span>
                                                            {/* <DownOutlined style={{ marginLeft: '1rem' }} /> */}
                                                        </>
                                                    }
                                                >
                                                    {item.children.map((submn, index) => {
                                                        return (
                                                            <Menu.Item
                                                                key={'sub-mn__child' + index}
                                                                onClick={() => setOpenNav(false)}
                                                            >
                                                                <NavLink to={submn.key}>{submn.label}</NavLink>
                                                            </Menu.Item>
                                                        );
                                                    })}
                                                </Menu.SubMenu>
                                            ) : (
                                                <Menu.Item key={index} onClick={() => setOpenNav(false)}>
                                                    <NavLink to={item.key}>{item.label}</NavLink>
                                                </Menu.Item>
                                            )}
                                        </Fragment>
                                    );
                                })}
                            </Menu>
                        </div>
                        {account ? (
                            <div className="boxFooterDrawer">
                                <div className="boxAccountAddress">
                                    <CopyToClipboard
                                        text={account}
                                        onCopy={() =>
                                            notification.success({
                                                message: 'Success',
                                                description: t('Header.copyWallet'),
                                                duration: 4,
                                            })
                                        }
                                    >
                    <span>
                      {ellipsisMiddle(account, 3, 5)} <CopyOutlined style={{marginLeft: 9}}/>
                    </span>
                                    </CopyToClipboard>
                                </div>
                                <button className="btnLogoutMobile" onClick={handleLogout}>
                                    <img src={IconLogout} style={{marginRight: 9}} alt=""/>
                                    {t('Header.btnLogout')}
                                </button>
                            </div>
                        ) : (
                            <button className="btn btn--connectWallet" onClick={onClickConnectWallet}>
                                {t('Header.btnLogin')}
                            </button>
                        )}
                    </div>
                </Drawer>
            </div>

            {/* desktop */}
            <div className={styles.boxHeader}>
                <div className={styles.logo}>
                    <a href="/">
                        <img src={logo} alt=""/>
                    </a>
                </div>
                <Menu className={styles.menuHeader} theme="light" mode={'horizontal'}>
                    {itemsHeader.map((item, index) => {
                        return (
                            <Fragment key={'desktop-item-header' + index}>
                                {item.children ? (
                                    <Menu.SubMenu
                                        key={'sub-mn__title' + index}
                                        title={
                                            <>
                                                <span>{item.label}</span>
                                                <DownOutlined style={{marginLeft: '1rem'}}/>
                                            </>
                                        }
                                    >
                                        {item.children.map((submn, index) => {
                                            return (
                                                <Menu.Item key={'sub-mn__child' + index}>
                                                    <NavLink to={submn.key}>{submn.label}</NavLink>
                                                </Menu.Item>
                                            );
                                        })}
                                    </Menu.SubMenu>
                                ) : (
                                    <Menu.Item key={index}>
                                        <NavLink to={item.key}>{item.label}</NavLink>
                                    </Menu.Item>
                                )}
                            </Fragment>
                        );
                    })}
                </Menu>
                <div className={styles.boxEndHeader}>
                    {account ? (
                        <div className={styles.boxAccountAddress}>
                            <span>{ellipsisMiddle(account, 3, 5)}</span>
                            <CopyToClipboard
                                text={account}
                                onCopy={() =>
                                    notification.success({
                                        message: 'Success',
                                        description: t('Header.copyWallet'),
                                        duration: 4,
                                    })
                                }
                            >
                                <CopyOutlined style={{margin: '0 9px'}}/>
                            </CopyToClipboard>
                            <span className="boxDropdownLogout">
                <Dropdown menu={{items}} trigger={['click']} overlayClassName="dropdownLogout">
                  <Space>
                    <DownOutlined/>
                  </Space>
                </Dropdown>
              </span>
                        </div>
                    ) : (
                        <button className="btn btn--connectWallet" onClick={onClickConnectWallet}>
                            {t('Header.btnLogin')}
                        </button>
                    )}
                    {account && checkToken() && userInfo?.address && (
                        <div className={styles.boxNoti}>
                            <BellFilled
                                className="alert-icon"
                                style={{
                                    fontSize: 20,
                                    cursor: 'pointer',
                                    marginTop: 5,
                                    color: '#18C3AF',
                                }}
                                onClick={(event: any) => {
                                    setOpenNotice(!openNotice);
                                }}
                            />
                            {ishaveNoti && (
                                <div
                                    style={{
                                        width: 10,
                                        height: 10,
                                        backgroundColor: 'red',
                                        borderRadius: '50%',
                                        position: 'absolute',
                                        top: 0,
                                    }}
                                ></div>
                            )}

                            {renderNoti()}
                        </div>
                    )}
                    <div className={styles.boxMutiLanguage}>
                        <Select
                            defaultValue={LANGUAGE.JAPAN}
                            value={i18n.language}
                            style={{width: 125}}
                            onChange={handleChangeLanguage}
                            bordered={false}
                            className="dropdown-change-lg"
                            options={[
                                {value: LANGUAGE.JAPAN, label: 'Japanese'},
                                {value: LANGUAGE.ENGLISH, label: 'English'},
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppHeader;
