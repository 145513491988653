import React, { useEffect, useState } from 'react';
import Carousel from 'components/carousel';
import useWindowSize from 'hooks/useWindowSize';
import { isMobileLayout } from 'utils/common';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { Base2ndNftPath, paths } from 'routes/routes';
import { useNavigate } from 'react-router-dom';
import Nft2ndServices from 'services/2nd-nft.service';
import { List2ndNftRequest } from 'models/2nd-nft';
import { ListResponse } from 'models/common';
import NotNft from 'components/not-found-nft';

const CarouselWrapper = React.memo(
  ({ size: SIZE, data }: any) => {
    return (
      <Carousel
        name="home-carousel"
        width={SIZE.CARD_SIZE + 'rem'}
        height={SIZE.CARD_SIZE + SIZE.INVERT_SHADOWN_SIZE + 'rem'}
      >
        {data.map((item, index) => {
          const imageUrl = item.preview2DObjectKey;

          return (
            <div className="card-wrapper" key={`carousel-2nd-nft-${item.id + index}`}>
              <div className="customize-card" key={`customize-card-${index}`}>
                <img src={imageUrl} alt="custmize card" />
              </div>

              <div
                style={
                  {
                    backgroundImage: `linear-gradient(180deg, #ffffff 40%, rgba(255, 255, 255, 0) 250%), url(${imageUrl})`,
                    '--invert-shadown-size': `${SIZE.INVERT_SHADOWN_SIZE}rem`,
                  } as any
                }
                className="revert"
              ></div>
            </div>
          );
        })}
      </Carousel>
    );
  },
  (preProps, nextProps) => isEqual(preProps, nextProps)
);

const LIMIT = 10;

const params: List2ndNftRequest = {
  page: 1,
  limit: LIMIT,
  type: 'top',
  sortField: 'created_at',
  desc: true,
};

const Customize = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const mobileLayout = isMobileLayout(width);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ListResponse<any>>({ items: [], totalCount: 0 });

  useEffect(() => {
    const fetch2ndNft = async () => {
      setLoading(true);
      const res = await Nft2ndServices.getList2ndNft(params, t);
      if (res) {
        setData(res);
      }
      setLoading(false);
    };

    fetch2ndNft();
  }, []);

  const handlerGoto2ndList = () => {
    navigate(Base2ndNftPath);
  };

  const size = React.useMemo(
    () => ({
      CARD_SIZE: !mobileLayout ? 40 : 30,
      INVERT_SHADOWN_SIZE: 8.5,
    }),
    [mobileLayout]
  );

  return (
    <>
      <div className="customize">
        <h1 className="section-title">{t('home.title.secondNft')}</h1>

        <div className="customize-container">
          {data.items.length ? (
            <CarouselWrapper size={size} data={data.items} />
          ) : (
            <NotNft title={t('buyNft.list.notFoundTitle')} />
          )}
        </div>

        <button className="btn btn--light-green" onClick={handlerGoto2ndList}>
          {t('home.button.seeMore')}
        </button>
      </div>
    </>
  );
};

export default Customize;
