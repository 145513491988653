import i18n from 'i18next';
import 'moment/min/locales.min';
import languageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import ja from 'assets/locale/ja/ja';
import en from 'assets/locale/en/en';
import { LANGUAGE } from 'constants/common';
import localStorageHelper, { KeyStorage } from 'utils/localStorage';


i18n
  .use(backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ja: {
        translation: {
          ...ja,
        },
      },
      en: {
        translation: {
          ...en,
        },
      },
    },
    react: {
      useSuspense: false,
    },
    backend: {
      allowMultiLoading: false,
    },
    lng: localStorageHelper.get(KeyStorage.I18n) || LANGUAGE.JAPAN,
    fallbackLng: LANGUAGE.JAPAN,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
