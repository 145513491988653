import { Modal, Space } from 'antd';
import GlobalLoading from 'components/global-loading';
import upbondServices from 'hooks/upbond-embed';
import { memo, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from 'routes/routes';
import { RootState } from 'stores/store';
import { API } from 'utils/api/api-route';
import ApiUtils from 'utils/api/api.utils';
import Web3 from 'web3';
import AppFooter from './footer';
import AppHeader from './header';
import './styles.scss';
import { loadingSystem, loadingSystemSuccess } from 'stores/system/systemSlice';
import { getUpbondTokenExpire, getUserInfo } from 'utils/auth/auth.utils';
import * as Sentry from '@sentry/react';
import { ThirdwebProvider, walletConnect } from '@thirdweb-dev/react';

const MainLayout = ({ children }: any) => {
  const _upbond = upbondServices.upbond.provider;
  const loading = useSelector((state: RootState) => state.system.globalLoading);
  const language = useSelector((state: RootState) => state.system.languageSystem);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = getUserInfo();
  const [listAccount, setAccount] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isExpire, setIsexpire] = useState(false);

  const account = userInfo?.address || listAccount[0];

  useEffect(() => {
    const rehydrate = async () => {
      const web3 = new Web3(_upbond);
      const accs = await web3.eth.getAccounts();
      if (accs.length > 0) {
        setAccount(accs);
      }
    };

    if (_upbond) {
      rehydrate();
    }
  }, [_upbond]);

  useEffect(() => {
    const initUpbond = async () => {
      try {
        dispatch(loadingSystem());
        await upbondServices.init(language);
      } catch (error) {
        dispatch(loadingSystemSuccess());
        console.error(`Error initialization: `, JSON.stringify(error));
      } finally {
        dispatch(loadingSystemSuccess());
      }
    };
    if (upbondServices.initialized) {
      return;
    }
    initUpbond();
  }, [dispatch, language]);

  useEffect(() => {
    const expireTime = getUpbondTokenExpire();

    const checkBlockUser = async () => {
      const res: any = await ApiUtils.post(API.checkBlockUser, { wallet: account });
      if (res?.isBlocked) {
        setIsOpen(true);
      }
    };

    if (new Date().getTime() - 10000 >= new Date(expireTime).getTime()) {
      setIsexpire(true);
      // トークンが古くなりすぎた場合、サイレントにログアウトするだけ
      // setIsOpen(true);
      upbondServices.logout();
      return;
    }

    if (account && pathname !== paths.contactUs) {
      Sentry.setUser({ username: account });
      checkBlockUser();
    }
  }, [account, pathname]);

  const onClickContactUs = () => {
    setIsOpen(false);
    navigate(paths.contactUs);
  };

  const onClickLogout = async () => {
    dispatch(loadingSystem());
    await upbondServices.logout();
    dispatch(loadingSystemSuccess());
    navigate(paths.login);
    window.location.reload();
  };

  return (
    <div className="wrapperLayout">
      <AppHeader account={account} />
      <ThirdwebProvider supportedWallets={[walletConnect()]}>
        <div className="bodyContent">{children}</div>
      </ThirdwebProvider>
      {pathname !== paths.login && <AppFooter />}
      {loading && <GlobalLoading isOpen={loading} />}
      <Modal
        open={isOpen}
        closable={false}
        width={isMobile ? '80%' : '30%'}
        className="customModalUserBlock"
        centered
        footer={null}
      >
        <p className="noti">
          {!isExpire ? t('common.messageUserBlocked') : t('common.messageExpireToken')}
        </p>
        <Space size={[24, 0]} align="center" style={{ justifyContent: 'center' }}>
          {!isExpire && (
            <button className="btn" onClick={onClickContactUs}>
              {t('common.btnContactAdmin')}
            </button>
          )}
          <button className="btn" onClick={onClickLogout}>
            {t('Header.btnReLogin')}
          </button>
        </Space>
      </Modal>
    </div>
  );
};

export default memo(MainLayout);
