/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useMemo, useState } from 'react';
import ModelViewer from 'components/model-viewer';
import { notification, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BaseBuySingleNft, paths } from 'routes/routes';
import useWindowSize from 'hooks/useWindowSize';
import { getCharacterName, isMobileLayout } from 'utils';
import { useTranslation } from 'react-i18next';
import Dragoon from 'assets/images/dragoon-logo.svg';
import Sential from 'assets/images/sentinel-logo.svg';
import BalanceOfPower from 'assets/images/balance-of-power.svg';
import TransactionService from 'services/transaction.service';
import { TransactionStatistic } from 'models/transaction';
import { get } from 'lodash';
import { JsxElement } from 'typescript';
import NotNft from 'components/not-found-nft';
import Nft1stServices from 'services/1st-nft.service';
import { LANGUAGE, TYPE_1ST } from 'constants/common';
import { FirstNftBuy, FirstNftBuyFilterRequest } from 'models/first-nft';
import { ListResponse } from 'models/common';
import { setSelectedNftState } from 'stores/common/nftSlice';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

const LIMIT = 3;

const params: FirstNftBuyFilterRequest = {
  page: 1,
  limit: LIMIT,
  filterType: TYPE_1ST.Body,
  desc: true,
  sortField: 'created_at',
  isListCombined: false,
};

const MIN_PERCENT_FOR_NOT_BREAK_LAYOUT = 4;
const MAX_PERCENT_FOR_NOT_BREAK_LAYOUT = 96;

const ModelCard = memo(({ src, index }: any) => {
  return (
    <div className={`model-card model-card--${index + 1}`}>
      <div className="container">
        <div className="model">
          <ModelViewer style={{ width: '100%', height: '100%' }} src={src} />
        </div>
      </div>
    </div>
  );
});

function Body() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const mobileLayout = isMobileLayout(width);
  const [data, setData] = useState<TransactionStatistic[]>([]);
  const [firstNftList, setFirstNftList] = useState<ListResponse<FirstNftBuy>>({ items: [], totalCount: 0 });

  useEffect(() => {
    const getBodyStatistic = async () => {
      try {
        const res = await TransactionService.getTransactionStatistic();
        setData(res);
      } catch (error: any) {
        console.error(`Error body statistic`, JSON.stringify(error));
        notification.error({ message: 'Error', description: error.message, duration: 4 });
      }
    };

    const getFirstNftList = async () => {
      const res = await Nft1stServices.getListBuy1stNft(params);
      if (res) setFirstNftList(res)
    }

    getFirstNftList();
    getBodyStatistic();
  }, []);

  const renderBodySection = useMemo((): {
    modelSection: JsxElement[];
    percent: number;
    processSection: JsxElement[];
    actionSection: JsxElement[];
  } => {
    let percent = 0;
    const synphyIndex = 0;
    const modelSection: any = [];
    const processSection: any = [];
    const actionSection: any = [];

    if (data.length) {
      const total = data.reduce((pre, curr) => pre + Number(curr.quantity), 0);
      percent = total ? Math.round((+get(data, `[${synphyIndex}].quantity`, 0) / total) * 100) : 50;
      const resPercent = 100 - percent;

      data.forEach((item, index) => {
        const isSynphy = synphyIndex === index;
        const clsName = isSynphy ? 'sylphy' : 'freiya';
        const handlerGotoBuySingle = () => {
          navigate(paths.detailBuyNft.replace(':id', item.nftId.toString()));
        };

        const handlerGotoBuyCustomize = () => {
          const getFirstNft = firstNftList?.items.find(({id})=> id === item.nftId);          
          if (getFirstNft?.id) {
            dispatch(setSelectedNftState(getFirstNft));
          } 
          navigate(`${BaseBuySingleNft}/weapon`);
        };

        modelSection.push(
          <ModelCard index={index} src={item?.presignUrl?.presignUrl} key={`model-card-${index}`} />
        );

        processSection.push(
          <div className="process-info" key={'process-info' + index}>
            <p className="title">
              {getCharacterName(item.characterName)}
              {percent > resPercent && isSynphy && <span>{t('WIN')}</span>}
            </p>
            <span className="percent">{`${isSynphy ? percent : resPercent}%`}</span>
          </div>
        );

        actionSection.push(
          <Space
            key={'action-section-' + index}
            size={[10, 10]}
            className="justify-center actions"
            direction={mobileLayout ? 'vertical' : 'horizontal'}
          >
            <button
              className={`btn btn--small btn--${clsName}-primary`}
              onClick={handlerGotoBuySingle}
            >
              {t('home.button.purchaseSingle')}
            </button>
            <button
              className={`btn btn--small btn--${clsName}-secondary`}
              onClick={handlerGotoBuyCustomize}
            >
              {t('home.button.customizePurchase')}
            </button>
          </Space>
        );
      });
    }

    return { modelSection, percent, processSection, actionSection };
  }, [data, t, mobileLayout]);

  const caculatedPercent = useMemo(() => {
    if (mobileLayout || renderBodySection.percent === 100 || renderBodySection.percent === 0)
      return renderBodySection.percent;

    return renderBodySection.percent < MIN_PERCENT_FOR_NOT_BREAK_LAYOUT
      ? MIN_PERCENT_FOR_NOT_BREAK_LAYOUT
      : renderBodySection.percent > MAX_PERCENT_FOR_NOT_BREAK_LAYOUT
      ? MAX_PERCENT_FOR_NOT_BREAK_LAYOUT
      : renderBodySection.percent;
  }, [renderBodySection.percent, mobileLayout]);

  if (data.length < 2) {
    return (
      <div className="body-section">
        <NotNft title={t('buyNft.list.notFoundTitle')} />
      </div>
    );
  }

  return (
    <div className="body-section">
      <div className="model-card-container">
        <>{renderBodySection.modelSection}</>
      </div>
      {mobileLayout && (
        <div className="team-logo-mobile">
          <div className="team-logo">
            <img src={Sential} alt="sentinel" />
          </div>
          <div className="team-logo">
            <img src={Dragoon} alt="dragoon" />
          </div>
        </div>
      )}

      {!mobileLayout && (
        <div className="power-container">
          <img src={BalanceOfPower} alt='balance of power' />
        </div>
      )}
      <div className="process-container__wrapper">
        <img src={Sential} alt="sentinel" className="team-logo team-logo__sentinel" />
        <div
          className={`process-container ${
            caculatedPercent === 100
              ? 'process-container--shylphy'
              : caculatedPercent === 0
              ? 'process-container--freiya'
              : ''
          }`}
          style={
            {
              '--process-percent': `${caculatedPercent}%`,
            } as any
          }
        >
          <> {renderBodySection.processSection}</>
        </div>
        <img src={Dragoon} alt="dragoon" className="team-logo team-logo__dragoon" />
      </div>

      <div className="actions-container">
        <>{renderBodySection.actionSection}</>
      </div>
    </div>
  );
}

export default Body;
