import { UserInfo } from 'models/common';
import localStorageHelper, { KeyStorage } from 'utils/localStorage';

export const getUserInfo = () => {
  try {
    const userInfo: UserInfo = localStorageHelper.getObject(KeyStorage.USER_INFO, localStorage);
    if (userInfo) {
      return userInfo as UserInfo;
    }
  } catch (error) {
    setUserInfo(null);
    return null;
  }
};

export const setUserInfo = (userInfo: UserInfo | null) => {
  localStorageHelper.setObject(
    KeyStorage.USER_INFO,
    {
      ...userInfo,
    },
    localStorage
  );
};

export const saveUpbondTokenExpire = () => {
  const newDate = new Date();
  newDate.setDate(newDate.getDate() + 3);
  localStorageHelper.set(KeyStorage.UPBOND_TOKEN_EXPIRE, newDate.toString());
};

export const getUpbondTokenExpire = (): string => {
  return localStorageHelper.get(KeyStorage.UPBOND_TOKEN_EXPIRE);
};

export const saveUpbondToken = (token: string, address: string) => {
  localStorageHelper.set(KeyStorage.UPBOND_TOKEN, `${address} ${token}`, localStorage);
};

export const getUpbondToken = (): string => {
  return localStorageHelper.get(KeyStorage.UPBOND_TOKEN, localStorage);
};
