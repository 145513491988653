import { Canvas } from '@react-three/fiber';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import Control from './component/control';
import Model from './component/model';
import { Model3dContextProvider } from './context';
import '@google/model-viewer';

const DemoAnimation = () => {
  const [animationLoad, setAnimationLoad] = useState(false);
  const [animationNames, setAnimationNames] = useState([]);
  const [animation, setAnimation] = useState('');

  useEffect(() => {
    const modelViewer = document.querySelector('#hihi');
    modelViewer.addEventListener('load', () => {
      modelViewer.pause();
      setAnimationLoad(true);
      setAnimationNames(modelViewer.availableAnimations);
    });
  }, []);

  useEffect(() => {
    if (animation) {
      const modelViewer = document.querySelector('#hihi');
      modelViewer.play();
      let t = modelViewer.duration;
      t = (t * 1000) / 2;
    }
  }, [animation]);

  const handlerAnimationClick = (name) => {
    setAnimation(name);
  };

  const captureImage = async () => {
    try {
      const modelViewer = document.getElementById('hihi');

      const url = modelViewer.toDataURL();
      const a = document.createElement('a');
      a.href = url;
      a.download = 'modelViewer_toDataURL.png';
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <div style={{ marginTop: '10rem' }}>
      <Model3dContextProvider>
        <Row>
          <Col span={8} style={{ alignSelf: 'flex-start' }}>
            <model-viewer
              id="hihi"
              src={
                'https://d2ok5yp7stnjtq.cloudfront.net/public/output/nft-image/admin/2/1/3/c9862be2-ba1d-4f30-8c53-6dbbdf545c3d_1.gltf'
              }
              ar
              ar-modes="webxr scene-viewer quick-look"
              camera-controls
              shadow-intensity="1"
              autoplay
              disable-pan
              animation-name={animation}
              skybox-image="https://media-cldnry.s-nbcnews.com/image/upload/t_fit-760w,f_auto,q_auto:best/streams/2013/March/130326/1C6639340-google-logo.jpg"
            ></model-viewer>
          </Col>
          <Col span={8}>
            <Control />
            {animationNames.map((item) => (
              <div
                onClick={() => {
                  handlerAnimationClick(item);
                }}
              >
                {item}
              </div>
            ))}
            <div
              onClick={() => {
                const modelViewer = document.querySelector('#hihi');
                modelViewer.play();
              }}
            >
              play
            </div>
            <div
              onClick={() => {
                const modelViewer = document.querySelector('#hihi');
                modelViewer.pause();
              }}
            >
              pause
            </div>
            <div onClick={captureImage}>capture image</div>
          </Col>
        </Row>
      </Model3dContextProvider>
    </div>
  );
};

export default DemoAnimation;
