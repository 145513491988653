import { RightOutlined, UpOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { ItemMenuFitter } from 'models/my-page';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  items: Array<ItemMenuFitter>;
  onSelectFilter: (item: any) => void;
  titleFilter: number | string;
}

const MenuFilterDesktop = ({ items, titleFilter, onSelectFilter }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <h2> {t('MyPage.menuFilter.titleLeft')}</h2>
      <Menu
        className="menuFilter"
        theme="light"
        mode={'inline'}
        style={{ borderInlineEnd: 'none' }}
        selectedKeys={[titleFilter.toString()]}
        defaultOpenKeys={['9']}
      >
        {items.map((item, index) => {
          return (
            <Fragment key={'menuFilter-' + index}>
              {!item.children ? (
                <Menu.Item key={item.key} className="itemMenu" onClick={() => onSelectFilter(item)}>
                  {item.icon}
                  <span>{item.label}</span>
                  <RightOutlined className="iconRight" />
                </Menu.Item>
              ) : (
                <Menu.SubMenu
                  key={item.key}
                  title={
                    <>
                      {item.icon}
                      <span>{item.label}</span>
                    </>
                  }
                  className="itemMenu"
                  icon={<UpOutlined className="iconRight icon-down" />}
                >
                  {item.children.map((submn, index) => {
                    return (
                      <Menu.Item
                        key={submn.key}
                        onClick={submn.onClick ? submn.onClick : onSelectFilter.bind(null, submn)}
                      >
                        <span>{submn.label}</span>
                        <RightOutlined className="iconRight" />
                      </Menu.Item>
                    );
                  })}
                </Menu.SubMenu>
              )}
            </Fragment>
          );
        })}
      </Menu>
    </>
  );
};

export default MenuFilterDesktop;
