import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes/routes';

const BottomButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const hanlderClick = () => {
    navigate(paths.upload2ndNft3D);
  };

  return (
    <div className="sell-secondary-btn">
      <button className="btn btn--large" onClick={hanlderClick}>
        {t('home.button.sellSecondNft')}
      </button>
    </div>
  );
};

export default BottomButton;
