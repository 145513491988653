import { Avatar } from 'antd';
import { UserProfile } from 'models/common';
import DefaultAvatar from 'assets/images/avatar-default.png';
import DefaultCover from 'assets/images/cover-default.png';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shortenAddress } from 'utils/short-word';
import { isMobile } from 'react-device-detect';
import ShowMoreText from 'react-show-more-text';

interface Props {
  profileUser: UserProfile | undefined;
  onClickEditProfile: () => void;
}

const ProfileUser = ({ profileUser, onClickEditProfile }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="boxMainInfo">
      <div className="boxImage">
        <img src={profileUser?.coverImg || DefaultCover} alt="cover" className="imageCover" />
        <div className="avatarWrapper">
          <Avatar size={160} src={profileUser?.avatarImg || DefaultAvatar} className="avatar" />
        </div>
      </div>
      <div className="boxInfo">
        <h3>
          {profileUser?.userName ||
            (isMobile
              ? shortenAddress(profileUser?.walletAddress, 15, 4)
              : profileUser?.walletAddress)}
        </h3>
        {isMobile ? (
          <ShowMoreText
            lines={3}
            more={
              <span className="actionShow">
                {/* <DownOutlined /> */}
                {t('MyPage.btnShowMore')}
              </span>
            }
            less={
              <span className="actionShow">
                {/* <UpOutlined /> */}
                {t('MyPage.btnShowLess')}
              </span>
            }
            anchorClass="showTextDes"
            className="showTextDes"
            expanded={false}
            truncatedEndingComponent={'..... '}
          >
            <p>{profileUser?.aboutMe}</p>
          </ShowMoreText>
        ) : (
          <p>{profileUser?.aboutMe}</p>
        )}
        <button className="btn btn--light-green" onClick={onClickEditProfile}>
          {t('MyPage.btnEditProfile')}
        </button>
      </div>
    </div>
  );
};

export default ProfileUser;
