import { RouteObject } from 'models/route';
import { lazy } from 'react';
import Home from 'features/home';
import DemoAnimation from 'features/demo-mode-animation';

const Login = lazy(() => import('features/login-upbond'));
const HowTo = lazy(() => import('features/how-to'));
const MyPage = lazy(() => import('features/my-page'));
const PrivacyAndPolicy = lazy(() => import('features/privacy-policy'));
const Terms = lazy(() => import('features/terms'));
const Faq = lazy(() => import('features/faq'));
const ContactUs = lazy(() => import('features/contact-us'));
const Nft2ndTop = lazy(() => import('features/nft2nd-top'));
const Nft2ndNewList = lazy(() => import('features/nft2nd-new-list'));
const Nft2ndRanking = lazy(() => import('features/nft2nd-ranking'));
const NftDetail = lazy(() => import('features/nft-detail'));
const Notifications = lazy(() => import('features/notifications'));
const NotificationsDetail = lazy(
  () => import('features/notifications/components/notificationDetail')
);
const Upload2ndNft3D = lazy(() => import('features/upload-2nd-nft/upload-nft-3d'));
const Upload2ndNft2D = lazy(() => import('features/upload-2nd-nft/upload-nft-2d'));

const SingleBuyPage = lazy(() => import('features/buy-nft/single-buy'));
const BuyIllustrationPage = lazy(() => import('features/buy-nft/buy-illustration'));
const CustomizeBuyPage = lazy(() => import('features/buy-nft/customize'));
const DetailBuyNftPage = lazy(() => import('features/buy-nft/detail-buy'));
const Artist = lazy(() => import('features/artist'));
const NFT2ndCreation = lazy(() => import('features/nft2nd-creation'));
const Payment = lazy(() => import('features/payment'));
const Download = lazy(() => import('features/download-nft'));
const WhatCanDo = lazy(() => import('features/what-can-do'));
const WhatIsDta = lazy(() => import('features/what-is-dta'));
const Community = lazy(() => import('features/community'));
const CompanyProflie = lazy(() => import('features/company-profile'));
const AttentionWhenUsing = lazy(() => import('features/attention-when-using'));

export const Base2ndNftPath = '/2nd-nft';
export const BaseNftDetail = '/nft-detail';
export const ArtishDetail = '/artist';
export const BaseDownload = '/download-nft';
export const routeNft2ndCreation = 'nft2nd-creation';
export const routePlayNft2nd = 'play';
export const BaseUpload2ndNft = '/upload-2nd';
export const BaseBuyNft = '/buy-nft';
export const BaseBuySingleNft = `${BaseBuyNft}/single`;
export const BaseBuyCustomize = `${BaseBuyNft}/customize`;

export const paths = {
  home: '/',
  singleBuyNft: `${BaseBuySingleNft}/:type`,
  singleBuyIllustration: `${BaseBuyNft}/illustration`,
  customizeBuyNft: `${BaseBuyCustomize}/:characterId/:bodyId`,
  detailBuyNft: `${BaseBuyNft}/detail/:id`,
  detailBuyNftIllustration: `${BaseBuyNft}/detail/:id/illustration`,
  login: '/login',
  notFound: '*',
  howto: '/how-to',
  upload2ndNft3D: '/upload-2nd-3D',
  upload2ndNft2D: '/upload-2nd-2D',
  myPage: '/my-page',
  privacyandpolicy: '/privacy-and-policy',
  terms: '/terms',
  faq: '/faq',
  contactUs: '/contact-us',
  nft2ndTop: Base2ndNftPath,
  nft2ndNewList: `${Base2ndNftPath}/new`,
  nft2ndRanking: `${Base2ndNftPath}/ranking`,
  nft2ndDetail: `${Base2ndNftPath}/:id`,
  notifications: '/notifications',
  notificationDetail: '/notifications/:id',
  artist: `${ArtishDetail}/:id`,
  nft2ndCreationOrPlay: '/:type/:id',
  payment: '/payment',
  nftDetail: `${BaseNftDetail}/:id`,
  download: `${BaseDownload}/:id`,
  whatCanDo: '/what-can-do',
  whatIsDta: '/what-is-dta-nft',
  community: '/community',
  companyProflie: '/company-profile',
  attentionWhenUsing: '/attention-when-using',
};

const mainRoutes: RouteObject[] = [
  { path: '/demo', component: DemoAnimation, isPublish: true },
  { path: paths.login, component: Login, isPublish: true },
  { path: paths.howto, component: HowTo, isPublish: true },
  { path: paths.privacyandpolicy, component: PrivacyAndPolicy, isPublish: true },
  { path: paths.terms, component: Terms, isPublish: true },
  { path: paths.faq, component: Faq, isPublish: true },
  { path: paths.contactUs, component: ContactUs, isPublish: true },
  { path: paths.home, component: Home, isPublish: true },
  { path: paths.singleBuyNft, component: SingleBuyPage, isPublish: true },
  { path: paths.singleBuyIllustration, component: BuyIllustrationPage, isPublish: true },
  { path: paths.customizeBuyNft, component: CustomizeBuyPage },
  { path: paths.detailBuyNft, component: DetailBuyNftPage },
  { path: paths.upload2ndNft3D, component: Upload2ndNft3D },
  { path: paths.upload2ndNft2D, component: Upload2ndNft2D },
  { path: paths.detailBuyNftIllustration, component: DetailBuyNftPage },
  { path: paths.myPage, component: MyPage },
  { path: paths.nft2ndTop, component: Nft2ndTop, isPublish: true },
  { path: paths.nft2ndNewList, component: Nft2ndNewList, isPublish: true },
  { path: paths.nft2ndRanking, component: Nft2ndRanking, isPublish: true },
  { path: paths.nftDetail, component: NftDetail, isPublish: true },
  { path: paths.notifications, component: Notifications, isPublish: true },
  { path: paths.notificationDetail, component: NotificationsDetail, isPublish: true },
  { path: paths.artist, component: Artist, isPublish: true },
  { path: paths.nft2ndCreationOrPlay, component: NFT2ndCreation },
  { path: paths.payment, component: Payment },
  { path: paths.download, component: Download, isPublish: true },
  { path: paths.whatCanDo, component: WhatCanDo, isPublish: true },
  { path: paths.whatIsDta, component: WhatIsDta, isPublish: true },
  { path: paths.community, component: Community, isPublish: true },
  { path: paths.companyProflie, component: CompanyProflie, isPublish: true },
  { path: paths.attentionWhenUsing, component: AttentionWhenUsing, isPublish: true },
];
export { mainRoutes };
