import AboutSection from './container/about';
import BodySection from './container/body';
import { WeaponContainer, IllustrationContainer } from './container/weapon-illustration-container';
import RecommendPresetSection from './container/recommend-preset';
import CustomizeSection from './container/customize';

import BottomButton from './container/bottom-btn';
import HomeBackground from './container/home-background';
import './index.scss';

/* トップページ */
export default function Home() {
  return (
    <div className="homepage">
      <HomeBackground />

      <AboutSection />

      <BodySection />

      <WeaponContainer />

      <RecommendPresetSection />

      <IllustrationContainer />

      <CustomizeSection />

      <BottomButton />
    </div>
  );
}
