import { LANGUAGE, TYPE_1ST } from 'constants/common';
import {
  FirstNftBuy,
  FirstNftBuyFilterRequest,
  FirstNftBuyIllustrationFilterRequest,
} from 'models/first-nft';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BaseBuyNft, paths } from 'routes/routes';
import { formatNumber } from 'utils';
import Nft1stServices from 'services/1st-nft.service';
import { ListResponse } from 'models/common';
import { Tooltip } from 'antd';
import NotNft from 'components/not-found-nft';

const LIMIT = 4;

const weaponParams: FirstNftBuyFilterRequest = {
  page: 1,
  limit: LIMIT,
  filterType: TYPE_1ST.Weapon,
  desc: true,
  sortField: 'created_at',
  isListCombined: false,
};

const illustrationparams: FirstNftBuyIllustrationFilterRequest = {
  page: 1,
  limit: LIMIT,
  desc: true,
  sortField: 'created_at',
};

type Props = {
  data: ListResponse<FirstNftBuy>;
  loading: boolean;
  title?: string;
  action: { title: string; onClick: () => void };
  onClick: (value: number) => void;
};

const Container = ({ data, loading, title, action, onClick: onHandlerClick }: Props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  if (!data.items.length)
    return (
      <div className="weapon-purchase">
        {title && <h1 className="section-title">{title}</h1>}
        <div style={{ textAlign: 'center' }}>
          <NotNft title={t('buyNft.list.notFoundTitle')} />
        </div>
      </div>
    );

  return (
    <>
      <div className="weapon-purchase">
        {title && <h1 className="section-title">{title}</h1>}

        <div
          className="weapon-container"
          style={
            {
              '--grid-item': data.items.length ?? LIMIT,
              '--padding': `${(LIMIT - data.items.length) * 12}%`,
            } as any
          }
        >
          {data.items.map((item, index) => (
            <div
              className="weapon"
              key={`weapon-purchase-${index}`}
              onClick={onHandlerClick.bind(null, item.id)}
            >
              <span>
                <div className="weapon__logo-wrapper">
                  <img src={item.previewImg} alt="weapon logo" />
                </div>
                <Tooltip title={item.name}>
                  <p className="weapon__name">{item.name}</p>
                </Tooltip>
                <Tooltip title={item.description}>
                  <p className="weapon__description">{item.description}</p>
                </Tooltip>
              </span>
              <p className="weapon__price">
                {formatNumber(lang === LANGUAGE.JAPAN ? item.price : item.priceUsd, lang)}(
                {t('Payment.includeTax')})
              </p>
            </div>
          ))}
        </div>

        <button className="btn btn--light-green" onClick={action.onClick}>
          {action.title}
        </button>
      </div>
    </>
  );
};

export const WeaponContainer = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ListResponse<FirstNftBuy>>({ items: [], totalCount: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWeapon = async () => {
      setLoading(true);
      const res = await Nft1stServices.getListBuy1stNft(weaponParams);
      if (res) {
        setData(res);
      }
      setLoading(false);
    };

    fetchWeapon();
  }, []);

  const handlerGotoBuyWeapon = () => {
    navigate(paths.singleBuyNft.replace(':type', TYPE_1ST.Weapon));
  };

  const handlerClickDetail = (id: number) => {
    navigate(paths.detailBuyNft.replace(':id', id.toString()));
  };

  return (
    <Container
      data={data}
      loading={loading}
      onClick={handlerClickDetail}
      action={{
        title: t('home.button.weaponPurchase'),
        onClick: handlerGotoBuyWeapon,
      }}
    />
  );
};

export const IllustrationContainer = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ListResponse<FirstNftBuy>>({ items: [], totalCount: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWeapon = async () => {
      setLoading(true);
      const res = await Nft1stServices.getListBuy1stIllustrationNft(illustrationparams);
      if (res?.items) {
        const firstNft = res.items.filter(({ description }) => description?.toLowerCase()?.includes('sylphy'));
        const othersNft = res.items.filter(({ description }) => !description?.toLowerCase()?.includes('sylphy'));
        res.items = firstNft.concat(othersNft);
        setData(res);
      }
      setLoading(false);
    };

    fetchWeapon();
  }, []);

  const handlerGotoUpload2ndNft = () => {
    navigate(`${BaseBuyNft}/illustration`);
  };

  const handlerClickDetail = (id: number) => {
    navigate(paths.detailBuyNftIllustration.replace(':id', id.toString()));
  };

  return (
    <Container
      data={data}
      loading={loading}
      onClick={handlerClickDetail}
      title={t('home.title.illustration')}
      action={{ title: t('home.button.illustrationPurchase'), onClick: handlerGotoUpload2ndNft }}
    />
  );
};
