import { Button, Col, Form, Input, notification, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import AvatarDefault from 'assets/images/avatar-default.png';
import CoverDefault from 'assets/images/cover-default.png';
import clsx from 'clsx';
import ImageUpload from 'components/image-upload';
import { USER_IMAGE_TYPE } from 'constants/common';
import { IUploadType, ResPresign, UPLOAD_TYPE, UserProfile } from 'models/common';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { API } from 'utils/api/api-route';
import ApiUtils from 'utils/api/api.utils';
import styles from '../styles.module.scss';

interface Image {
  file: File;
  name: string;
}
export interface ProfileData {
  artistName: string;
  description: string;
  avatarImage: Image;
  coverImage: Image;
}

interface Props {
  profileUser: UserProfile | undefined;
  handleUpdateProfileSuccess: () => void;
}

const ProfileEdit = ({ profileUser, handleUpdateProfileSuccess }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      artistName: profileUser?.userName || '',
      description: profileUser?.aboutMe || '',
      avatarImage: { file: profileUser?.avatarImg },
      coverImage: { file: profileUser?.coverImg },
    });
  }, [form, profileUser]);

  const onFinish = async (values: ProfileData) => {
    setLoading(true);
    const dataUpdate = {
      userName: values.artistName.trim(),
      aboutMe: values.description.trim(),
      deleteAvatar: !values?.avatarImage?.file,
      deleteCover: !values?.coverImage?.file,
    };

    try {
      Promise.allSettled([
        ApiUtils.patch(API.profileUser, { ...dataUpdate }),
        values?.avatarImage?.file &&
          typeof values?.avatarImage?.file !== 'string' &&
          handlePresign(values?.avatarImage, USER_IMAGE_TYPE.AVATAR),
        values?.coverImage?.file &&
          typeof values?.coverImage?.file !== 'string' &&
          handlePresign(values?.coverImage, USER_IMAGE_TYPE.COVER),
      ]).then((results) => {
        if (results[0].status === 'rejected') {
          console.error(`Error presign rejected : Upload information failed`);
          notification.error({
            message: 'Error',
            description: 'Upload information failed',
            duration: 4,
          });
          setLoading(false);
          return;
        }
        if (results[1].status === 'rejected') {
          console.error(`Error presign rejected : Upload avatar image failed`);
          notification.error({
            message: 'Error',
            description: 'Upload avatar image failed',
            duration: 4,
          });
          setLoading(false);
          return;
        }
        if (results[2].status === 'rejected') {
          console.error(`Error presign rejected : Upload cover image failed`);
          notification.error({
            message: 'Error',
            description: 'Upload cover image failed',
            duration: 4,
          });
          setLoading(false);
          return;
        }
        notification.success({
          message: 'Success',
          description: t('profileEdit.messageSuccess'),
          duration: 4,
        });
        handleUpdateProfileSuccess();
        setLoading(false);
      });
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handlePresign = async (value: Image, typeImg) => {
    try {
      const resPresign: ResPresign = await ApiUtils.post(API.presignUser, {
        type: typeImg,
        name: value?.name || '',
      });
      await handleUploadS3(resPresign, value?.file);
    } catch (error) {
      console.error(`Error handle presign`, JSON.stringify(error));
      console.log(error?.message);
    }
  };

  const handleUploadS3 = async (resPresign: ResPresign, fileImagePresign: File) => {
    try {
      await ApiUtils.postS3(resPresign?.url, fileImagePresign, resPresign?.headers, true);
    } catch (error) {
      console.error(`Error handle upload s3`, JSON.stringify(error));
      notification.error({
        message: 'Error',
        description: 'Upload image failed',
        duration: 4,
      });
    }
  };

  return (
    <div className={styles.profileEditContainer}>
      <div className={styles.editProfileForm}>
        <h2>{t('profileEdit.profileSettingTitle')}</h2>
        <Form onFinish={onFinish} form={form} layout={isMobile ? 'vertical' : 'horizontal'}>
          <Row gutter={40}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 15 }} lg={{ span: 15 }}>
              <Form.Item
                name="artistName"
                label={t('profileEdit.artistName')}
                rules={[
                  {
                    max: 50,
                    message: t('profileEdit.maxWordName'),
                  },
                  {
                    validator: (_, value) =>
                      value.trim()
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('profileEdit.requireInput'))),
                  },
                ]}
                labelCol={{ span: 24 }}
              >
                <Input style={{ height: '40px' }} />
              </Form.Item>
              <Form.Item
                name="description"
                label={t('profileEdit.explanatoryText')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    max: 750,
                    message: t('profileEdit.maxWordDes'),
                  },
                ]}
              >
                <TextArea rows={10} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 9 }} lg={{ span: 9 }}>
              <Form.Item
                label={
                  <p className={styles.avatarSelectTitle}>{t('profileEdit.avatarImageTitle')}</p>
                }
                labelCol={{ span: 24 }}
                name="avatarImage"
              >
                <ImageUpload
                  defaultImage={AvatarDefault}
                  shape={IUploadType.CIRCLE}
                  maxSize={5}
                  size={{ width: 150, height: 150 }}
                  type={UPLOAD_TYPE.IMAGE}
                  updateProfile={true}
                />
              </Form.Item>
              <Form.Item
                label={
                  <p className={styles.coverImageSelectTitle}>{t('profileEdit.coverImageTitle')}</p>
                }
                labelCol={{ span: 24 }}
                name="coverImage"
              >
                <ImageUpload
                  defaultImage={CoverDefault}
                  shape={IUploadType.RETANGLE}
                  maxSize={5}
                  size={isMobile ? { width: '100%', height: 150 } : { width: 150, height: 120 }}
                  type={UPLOAD_TYPE.IMAGE}
                  updateProfile={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Button
            className={clsx(styles.submitProfileButton, 'btn', 'btn--cardNft')}
            htmlType="submit"
            size="large"
            loading={loading}
          >
            {t('profileEdit.submitButton')}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ProfileEdit;
