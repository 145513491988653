import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { FILTER_MY_PAGE } from 'features/my-page';
import { ItemMenuFitter } from 'models/my-page';
import React, { useEffect } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes/routes';
import styles from './styles.module.scss';

export function BtnSelect({
  item,
  selected,
  onClick,
}: {
  item: ItemMenuFitter;
  selected: boolean;
  onClick: () => void;
}) {
  return (
    <button
      className={styles.btnSelectFilter}
      tabIndex={0}
      style={{
        backgroundColor: selected ? '#18C4AF' : '#fff',
        color: selected ? '#fff' : '#424242',
      }}
      onClick={() => onClick()}
    >
      {item.icon}
      <span className={styles.btnTitle}>{item?.label}</span>
    </button>
  );
}

interface Props {
  items: Array<ItemMenuFitter>;
  onSelectFilter: (item: ItemMenuFitter) => void;
  location?: any;
}
function SelectHorizontalMenu({ items, onSelectFilter, location }: Props) {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<number>(1);
  const navigate = useNavigate();
  const handleItemClick = (item: ItemMenuFitter) => () => {
    setSelected(item.key as number);
    onSelectFilter(item);
  };

  const itemsDropdown = [
    {
      label: t('MyPage.menuFilter.menu11'),
      key: 11,
      filter: FILTER_MY_PAGE.REQUEST_2ND,
      onClick: () => {
        navigate(paths.upload2ndNft3D);
      },
    },
    {
      label: t('MyPage.menuFilter.menu13'),
      key: 13,
      filter: FILTER_MY_PAGE.REQUEST_2ND,
      onClick: () => {
        navigate(paths.upload2ndNft2D);
      },
    },
    {
      label: t('MyPage.menuFilter.menu12'),
      key: 12,
      filter: FILTER_MY_PAGE.REQUEST_2ND,
      onClick: handleItemClick({
        label: t('MyPage.menuFilter.menu12'),
        key: 12,
        filter: FILTER_MY_PAGE.REQUEST_2ND,
      }),
    },
  ];

  useEffect(() => {
    if (location?.state?.menu) {
      setSelected(location?.state?.menu);
    }
  }, [location]);

  return (
    <ScrollMenu wrapperClassName={styles.wrapperSelectFilter}>
      {items.map((item: ItemMenuFitter) => {
        if (item?.children) {
          return (
            <Dropdown menu={{ items: itemsDropdown }} placement="bottomLeft" arrow key={item.key}>
              <button
                key={item.key}
                className={styles.btnSelectFilter}
                tabIndex={0}
                style={{
                  backgroundColor: 12 === selected ? '#18C4AF' : '#fff',
                  color: 12 === selected ? '#fff' : '#424242',
                }}
              >
                {item.icon}
                <span className={styles.btnTitle}>{item?.label}</span>
                <DownOutlined />
              </button>
            </Dropdown>
          );
        } else {
          return (
            <BtnSelect
              item={item}
              key={item.key}
              onClick={handleItemClick(item)}
              selected={item.key === selected}
            />
          );
        }
      })}
    </ScrollMenu>
  );
}

export default SelectHorizontalMenu;
