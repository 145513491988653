import {
  AimOutlined,
  ApartmentOutlined,
  ApiOutlined,
  ControlOutlined,
  CreditCardOutlined,
  DeliveredProcedureOutlined,
  FileImageOutlined,
  GroupOutlined,
  HeartOutlined,
  SearchOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Col, Form, Input, Modal, Pagination, Row, Select, Space, notification } from 'antd';
import NotNft from 'components/not-found-nft';
import SelectHorizontalMenu from 'components/select-horizontal-menu/select-horizontal-menu';
import { debounce } from 'lodash';
import { DataRes2nd, List2ndNftRequest } from 'models/2nd-nft';
import { LikeRes, ListResponse, UserProfile } from 'models/common';
import { ItemMenuFitter } from 'models/my-page';
import { NftDataRes, OwnedNftRes, OwnedNft } from 'models/nft';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { BaseNftDetail, paths, routeNft2ndCreation, routePlayNft2nd } from 'routes/routes';
import { likeNft } from 'services/artist-service';
import { useAppDispatch } from 'stores/hooks';
import { fetchCharacter } from 'stores/master/actions';
import { loadingSystem, loadingSystemSuccess } from 'stores/system/systemSlice';
import { API } from 'utils/api/api-route';
import ApiUtils from 'utils/api/api.utils';
import { getUserInfo } from 'utils/auth/auth.utils';
import CardItemNft from './components/card-item-nft';
import CardItemNftApproveReject from './components/item-nft-request/card-item-nft-approve-reject';
import Detail2stApproveRejectModal from './components/item-nft-request/detail-2st-approve-reject-modal';
import MenuFilterDesktop from './components/menu-filter-desktop';
import ProfileUser from './components/profile/profile';
import ProfileEdit from './components/profile/profile-edit';
import TableTransactions from './components/transaction-history/table-transaction';
import './styles.scss';

export enum FILTER_MY_PAGE {
  ALL = 'all',
  BODY = 'body',
  PRESET = 'preset',
  WEAPON = 'weapon',
  FIRST_NFT = 'firstNft',
  NFT_2D = 'firstNftSimple',
  SECOND_NFT = 'secondNft',
  FAVORITE = 'favorite',
  TRANSACTION = 'transactions',
  TRADEDNFT = 'tradedNft',
  REQUEST_2ND = 'up_load',
  DROP_DOWN = 'drop_down',
}

interface FilterData {
  page: number;
  limit: number;
  filterType?: string;
  name?: string;
  artistId: number | string;
}

const LIMIT = 12;
const initFilterValue: FilterData = {
  page: 1,
  limit: LIMIT,
  filterType: FILTER_MY_PAGE.FIRST_NFT,
  name: '',
  artistId: 0,
};

const initialFilter2nd: List2ndNftRequest = {
  page: 1,
  limit: LIMIT,
  type: FILTER_MY_PAGE.REQUEST_2ND,
  statusUpload: '',
  characterId: '',
};

const MyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [listDataNft, setListDataNft] = useState<Array<NftDataRes>>([]);
  const [titleFilter, setTitleFilter] = useState<number>(1);
  const [filter, setFilter] = useState<FilterData>(initFilterValue);
  const [totalItem, setTotalItem] = useState<number>(1);
  const [openModal2ndNftStatus, setOpenModal2ndNftStatus] = useState<boolean>(false);
  const [id2ndNftSelected, setId2ndNftSelected] = useState<string | number>('');
  const [profileUser, setProfileUser] = useState<UserProfile>();
  const [statusUpdateProfile, setStatusUpdateProfile] = useState(false);
  const [filter2ndRequest, setFilter2ndRequest] = useState<List2ndNftRequest>(initialFilter2nd);
  const [listData2ndRequest, setListData2ndRequest] = useState<Array<DataRes2nd>>([]);
  const [nftOnwedList, setNftOwnedList] = useState<Array<OwnedNft>>([]);
  const userInfo = getUserInfo();

  const fetchData = useCallback(async () => {
    // 購入済NFTの個数を集計
    const res: OwnedNftRes = await ApiUtils.fetch(API.nftOwnedList);
    setNftOwnedList(res.items);

    if (filter.filterType === FILTER_MY_PAGE.TRANSACTION) {
      return;
    }
    dispatch(loadingSystem());
    if (filter.filterType === FILTER_MY_PAGE.REQUEST_2ND) {
      try {
        const dataRequest = {
          page: filter.page,
          limit: LIMIT,
          type: filter2ndRequest.type,
          statusUpload: filter2ndRequest?.statusUpload,
          characterId: filter2ndRequest?.characterId,
          sortField: 'created_at',
          desc: true,
        };
        const response: ListResponse<DataRes2nd> = await ApiUtils.fetch(API.nftList2nd, {
          ...dataRequest,
        });
        setListData2ndRequest(response.items);
        setTotalItem(response.totalCount);
      } catch (error) {
        console.error('Error nft owned list', JSON.stringify(error));
        notification.error({
          message: 'Error',
          description: t('common.messageWrong'),
          duration: 4,
        });
      } finally {
        dispatch(loadingSystemSuccess());
      }
      return;
    }

    try {
      const dataRequest = {
        page: filter.page,
        limit: LIMIT,
        filterType:
          filter.filterType === FILTER_MY_PAGE.FAVORITE ? FILTER_MY_PAGE.ALL : filter.filterType,
        name: filter.name,
        artistId: userInfo?.id,
        isFavorite: filter.filterType === FILTER_MY_PAGE.FAVORITE ? true : false,
        sortField: 'created_at',
        desc: true,
      };

      const response: ListResponse<NftDataRes> = await ApiUtils.fetch(
        API.listMyPageNft,
        dataRequest
      );

      setListDataNft(response.items);
      setTotalItem(response.totalCount);
    } catch (error) {
      console.error('Error nft owned list', JSON.stringify(error));
      notification.error({ message: 'Error', description: t('common.messageWrong'), duration: 4 });
    } finally {
      dispatch(loadingSystemSuccess());
    }
  }, [filter.filterType, filter.page, filter.name, dispatch, t, userInfo?.id, filter2ndRequest]);

  useEffect(() => {
    const getInfoUser = async () => {
      const res: UserProfile = await ApiUtils.fetch(`${API.profileUser}/${userInfo?.id}`);
      setProfileUser(res);
    };
    if (userInfo?.id) {
      getInfoUser();
    }
  }, [userInfo?.id, statusUpdateProfile]);

  useEffect(() => {
    dispatch(fetchCharacter());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSelectFilter = (item: ItemMenuFitter) => {
    setTitleFilter(item?.key);
    setFilter({ ...filter, filterType: item?.filter, page: 1 });
  };

  const renderTitle = useCallback(() => {
    switch (titleFilter) {
      case 1:
        return t('MyPage.titleFilter.title1');
      case 2:
        return t('MyPage.titleFilter.title2');
      case 3:
        return t('MyPage.titleFilter.title3');
      case 4:
        return t('MyPage.titleFilter.title4');
      case 5:
        return t('MyPage.titleFilter.title5');
      case 6:
        return t('MyPage.titleFilter.title6');
      case 7:
        return t('MyPage.titleFilter.title7');
      case 8:
        return t('MyPage.titleFilter.title8');
      case 9:
        return t('MyPage.titleFilter.title9');
      case 10:
        return t('MyPage.titleFilter.title10');
      case 12:
        return t('MyPage.titleFilter.title12');
      case 100:
        return t('MyPage.titleFilter.title11');
      default:
        return t('MyPage.titleFilter.title1');
    }
  }, [titleFilter, t]);

  const items: Array<ItemMenuFitter> = [
    {
      label: t('MyPage.menuFilter.menu1'),
      key: 1,
      filter: FILTER_MY_PAGE.FIRST_NFT,
      icon: <GroupOutlined style={{ fontSize: 20 }} />,
    },
    {
      label: t('MyPage.menuFilter.menu2'),
      key: 2,
      filter: FILTER_MY_PAGE.BODY,
      icon: <DeliveredProcedureOutlined style={{ fontSize: 20 }} />,
    },
    {
      label: t('MyPage.menuFilter.menu3'),
      key: 3,
      filter: FILTER_MY_PAGE.WEAPON,
      icon: <AimOutlined style={{ fontSize: 20 }} />,
    },
    // {
    //   label: t('MyPage.menuFilter.menu4'),
    //   key: 4,
    //   filter: FILTER_MY_PAGE.PRESET,
    //   icon: <ControlOutlined style={{ fontSize: 20 }} />,
    // },

    {
      label: t('MyPage.menuFilter.menu5'),
      key: 5,
      filter: FILTER_MY_PAGE.NFT_2D,
      icon: <FileImageOutlined style={{ fontSize: 20 }} />,
    },

    {
      label: t('MyPage.menuFilter.menu6'),
      key: 6,
      filter: FILTER_MY_PAGE.SECOND_NFT,
      icon: <ApartmentOutlined style={{ fontSize: 20 }} />,
    },
    {
      label: t('MyPage.menuFilter.menu7'),
      key: 7,
      filter: FILTER_MY_PAGE.TRADEDNFT,
      icon: <CreditCardOutlined style={{ fontSize: 20 }} />,
    },
    {
      label: t('MyPage.menuFilter.menu8'),
      key: 8,
      filter: FILTER_MY_PAGE.FAVORITE,
      icon: <HeartOutlined style={{ fontSize: 20 }} />,
    },
    {
      label: t('MyPage.menuFilter.menu9'),
      key: 9,
      filter: FILTER_MY_PAGE.ALL,
      icon: <ApiOutlined style={{ fontSize: 20 }} />,
      children: [
        {
          label: t('MyPage.menuFilter.menu11'),
          key: 11,
          filter: FILTER_MY_PAGE.REQUEST_2ND,
          icon: <ApiOutlined style={{ fontSize: 20 }} />,
          onClick: () => {
            navigate(paths.upload2ndNft3D);
          },
        },
        {
          label: t('MyPage.menuFilter.menu13'),
          key: 13,
          filter: FILTER_MY_PAGE.REQUEST_2ND,
          icon: <ApiOutlined style={{ fontSize: 20 }} />,
          onClick: () => {
            navigate(paths.upload2ndNft2D);
          },
        },
        {
          label: t('MyPage.menuFilter.menu12'),
          key: 12,
          filter: FILTER_MY_PAGE.REQUEST_2ND,
          icon: <ApiOutlined style={{ fontSize: 20 }} />,
        },
      ],
    },
    {
      label: t('MyPage.menuFilter.menu10'),
      key: 10,
      filter: FILTER_MY_PAGE.TRANSACTION,
      icon: <WalletOutlined style={{ fontSize: 20 }} />,
    },
  ];

  const onFilterCharacter = useCallback(
    (value: string | number) => {
      setFilter2ndRequest({ ...filter2ndRequest, characterId: value });
      setFilter({ ...filter, page: 1 });
    },
    [filter2ndRequest, filter]
  );

  const onFilterStatus = useCallback(
    (value: string) => {
      setFilter2ndRequest({ ...filter2ndRequest, statusUpload: value });
      setFilter({ ...filter, page: 1 });
    },
    [filter2ndRequest, filter]
  );

  const renderHeaderActionElement = useCallback(() => {
    if (titleFilter === 12) {
      return (
        <Form>
          <Space direction="horizontal" style={{ justifyContent: 'flex-end' }} size={[16, 16]}>
            <Form.Item name="character" label={t('MyPage.list2ndRequest.filterCharacter')}>
              <Select
                options={[
                  {
                    value: 1,
                    label: t('common.shylphy'),
                  },
                  {
                    value: 2,
                    label: t('common.freyja'),
                  },
                  {
                    value: 3,
                    label: t('common.berilPawn'),
                  },
                  {
                    value: 4,
                    label: t('common.berilKnight'),
                  },
                  {
                    value: 5,
                    label: t('common.berilBishop'),
                  },
                  {
                    value: 6,
                    label: t('common.berilLuke'),
                  },
                  {
                    value: 7,
                    label: t('common.shadePawn'),
                  },
                  {
                    value: 8,
                    label: t('common.shadeKnight'),
                  },
                  {
                    value: 9,
                    label: t('common.shadeBishop'),
                  },
                  {
                    value: 10,
                    label: t('common.shadeLuke'),
                  },
                  {
                    value: 11,
                    label: t('common.berilKing'),
                  },
                  {
                    value: 12,
                    label: t('common.berilQueen'),
                  },
                  {
                    value: 13,
                    label: t('common.shadeKing'),
                  },
                  {
                    value: 14,
                    label: t('common.shadeQueen'),
                  },
                ]}
                onChange={onFilterCharacter}
                allowClear={true}
              />
            </Form.Item>
            <Form.Item name="status" label={t('MyPage.list2ndRequest.filterStatus')}>
              <Select
                options={[
                  { value: 'REVIEW', label: t('MyPage.list2ndRequest.Review') },
                  { value: 'PENDING', label: t('MyPage.list2ndRequest.Pending') },
                  { value: 'REJECT', label: t('MyPage.list2ndRequest.Rejected') },
                  { value: 'APPROVED', label: t('MyPage.list2ndRequest.Approved') },
                ]}
                onChange={onFilterStatus}
                allowClear={true}
              />
            </Form.Item>
          </Space>
        </Form>
      );
    }

    return null;
  }, [titleFilter, onFilterCharacter, onFilterStatus, t]);

  const onClickBtnCustomNFT = (id: number | string) => {
    navigate(`/${routeNft2ndCreation}/${id}`);
  };

  const onClickBtnPlayNFT = (id: number | string) => {
    navigate(`/${routePlayNft2nd}/${id}`);
  };

  const onClickDetailNFT = (item: NftDataRes) => {
    navigate(`${BaseNftDetail}/${item.id}`);
  };

  const onClickEditProfile = () => {
    setTitleFilter(100);
  };

  const handleChangePage = (page: number) => {
    setFilter({ ...filter, page: page });
  };

  const handleChangeTotalLike = useCallback(
    (id: number | string, result: LikeRes) => {
      const cloneListDataNft = [...listDataNft];
      const newListNfts = cloneListDataNft.map((item: NftDataRes, key: number) => {
        if (item.nftId === id) {
          return { ...item, countLike: result?.totalLikes, isLiked: result?.isLiked };
        } else return item;
      });
      setListDataNft(newListNfts);
    },
    [listDataNft]
  );

  const handleLike = useCallback(
    async (id: number | string) => {
      const result = await likeNft(id);
      handleChangeTotalLike(id, result);
    },
    [handleChangeTotalLike]
  );

  const debounceLike = debounce((id: string | number) => handleLike(id), 300);

  const onClickNftApproveReject = (id: string | number) => {
    setOpenModal2ndNftStatus(true);
    setId2ndNftSelected(id);
  };

  const onCloseModalApproveReject = () => {
    setOpenModal2ndNftStatus(false);
  };

  const handleChangeFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, name: e.target.value });
  };

  const handleUpdateProfileSuccess = () => {
    setTitleFilter(1);
    setStatusUpdateProfile(!statusUpdateProfile);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (location?.state?.menu) {
      setTitleFilter(location?.state?.menu);
      setFilter({ ...initFilterValue, filterType: FILTER_MY_PAGE.REQUEST_2ND });
      if (location?.state?.nftId) {
        onClickNftApproveReject(location?.state?.nftId);
      }
    }
  }, [location]);

  return (
    <div className="containerMyPage">
      {titleFilter !== 100 && (
        <ProfileUser profileUser={profileUser} onClickEditProfile={onClickEditProfile} />
      )}
      <div className="boxMainContent">
        <div className="boxMenuFilter">
          {!isMobile ? (
            <MenuFilterDesktop
              items={items}
              onSelectFilter={onSelectFilter}
              titleFilter={titleFilter}
            />
          ) : (
            <SelectHorizontalMenu
              items={items}
              onSelectFilter={onSelectFilter}
              location={location}
            />
          )}
        </div>

        {titleFilter === 100 ? (
          <ProfileEdit
            profileUser={profileUser}
            handleUpdateProfileSuccess={handleUpdateProfileSuccess}
          />
        ) : (
          <div className="boxContentFilter">
            <Row gutter={[25, 0]} style={{ marginBottom: isMobile ? 10 : 25 }}>
              <Col
                md={12}
                xs={24}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <h2 className="titleContent">{renderTitle()}</h2>
              </Col>
              {renderHeaderActionElement() ? (
                <Col md={12} xs={24}>
                  {renderHeaderActionElement()}
                </Col>
              ) : (
                <Col
                  md={12}
                  xs={24}
                  style={{
                    display: 'flex',
                    justifyContent: isMobile ? 'flex-start' : 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <div className="boxInputSearch">
                    {filter.filterType !== FILTER_MY_PAGE.TRANSACTION && (
                      <Input
                        onChange={debounce(handleChangeFilter, 500)}
                        prefix={<SearchOutlined />}
                        placeholder={t('MyPage.placeSearch')}
                        size="large"
                        allowClear={true}
                        onPressEnter={(event) => event.preventDefault()}
                      />
                    )}
                  </div>
                </Col>
              )}
            </Row>
            {filter.filterType === FILTER_MY_PAGE.TRANSACTION ? (
              <TableTransactions key={10} />
            ) : (
              <>
                <Row gutter={[16, 25]}>
                  {titleFilter === 12 ? (
                    listData2ndRequest.length > 0 ? (
                      listData2ndRequest.map((item: DataRes2nd, index: number) => (
                        <Col key={index} xs={24} sm={6}>
                          <CardItemNftApproveReject
                            item={item}
                            onClickNFT={() => onClickNftApproveReject(item?.nftId)}
                          />
                        </Col>
                      ))
                    ) : (
                      <div className="notFoundNft">
                        <NotNft isBorder={true} filterName={filter.name} />
                      </div>
                    )
                  ) : listDataNft.length > 0 ? (
                    listDataNft.map((item: NftDataRes, index: number) => (
                      <Col key={index} xs={24} sm={6}>
                        <CardItemNft
                          item={item}
                          nftOnwedList={nftOnwedList}
                          onClickBtnCustomNFT={onClickBtnCustomNFT}
                          onClickDetailNFT={onClickDetailNFT}
                          onClickBtnPlayNFT={onClickBtnPlayNFT}
                          handleLike={debounceLike}
                          titleFilter={titleFilter}
                        />
                      </Col>
                    ))
                  ) : (
                    <div className="notFoundNft">
                      <NotNft
                        isHaveButton={true}
                        isBorder={true}
                        typeButton={filter.filterType}
                        filterName={filter.name}
                      />
                    </div>
                  )}
                </Row>
                <div className="footerPagination">
                  {totalItem > LIMIT && (
                    <Pagination
                      showSizeChanger={false}
                      current={filter.page}
                      defaultPageSize={LIMIT}
                      onChange={handleChangePage}
                      showLessItems
                      total={totalItem}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <Modal
        title={t('MyPage.list2ndRequest.titlePopup')}
        onCancel={onCloseModalApproveReject}
        open={openModal2ndNftStatus}
        footer={null}
        destroyOnClose
        width={isMobile ? '90%' : '75%'}
        className="modalDetail2ndNft"
      >
        <Detail2stApproveRejectModal id={id2ndNftSelected} profileUser={profileUser} />
      </Modal>
    </div>
  );
};

export default MyPage;
