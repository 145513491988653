import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './stores/store';
import 'i18n/i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from '@sentry/react';
import ReactGA from "react-ga4";

const container = document.getElementById('root')!;
const root = createRoot(container);
const persistor = persistStore(store);

const options = {
  testMode: process.env.REACT_APP_SENTRY_ENVIRONMENT === 'production' ? false : true,
};

ReactGA.initialize(process.env.REACT_APP_GA_TRACKIND_ID ?? '', options);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", /^https:\/\/be\.stg\.dta-nft\.jp/, /^https:\/\/be\.dta-nft\.jp/],
    }),
    new CaptureConsole({
      levels: ['error']
    })
  ],
  // Performance Monitoring
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
});

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
