import React from 'react';

import HomeModelBg1x from '../../../assets/images/home-model.png';
import HomeModelBg2x from '../../../assets/images/home-model-2x.png';
import Logo450 from '../../../assets/images/logo-450.png';
import Logo900 from '../../../assets/images/logo-900.png';
import Logo1360 from '../../../assets/images/logo-1360.png';
import Logo1800 from '../../../assets/images/logo-1800.png';

const HomeBackground = () => {
  return (
    <div className="background">
      <div className="background__logo-wrapper">
        <img
          className="logo"
          alt="background logo"
          src={Logo1800}
          srcSet={`${Logo450} 450w, ${Logo900} 900w, ${Logo1360} 1360w, ${Logo1800} 1800w`}
        />
      </div>
      <img
        className="background__model"
        alt="background model"
        src={HomeModelBg1x}
        srcSet={`${HomeModelBg1x} 800w, ${HomeModelBg2x} 1600w`}
      />
    </div>
  );
};

export default HomeBackground;
