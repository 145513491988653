import { notification } from "antd";
import { API } from "utils/api/api-route";
import ApiUtils from "utils/api/api.utils";

const getListNoti = async (params) => {
  try {
    const response: any = await ApiUtils.fetch(
      API.notiList,
      params
    );
    return response;
  } catch (error) {
    notification.error({
      message: 'Error',
      description: error?.message,
      duration: 4,
    });;
  }
}

const markRead = async () => {
  try {
    const result: any = await ApiUtils.put(
      API.markRead
    );
    return result?.isSuccess
  } catch (error) {
    notification.error({
      message: 'Error',
      description: error?.message,
      duration: 4,
    });;
  }
}

const readOneNoti = async (id: number) => {
  try {
    const result = await ApiUtils.put(
      `${API.notiList}/${id}`
    );
    return result
  } catch (error) {
    notification.error({
      message: 'Error',
      description: error?.message,
      duration: 4,
    });;
  }
}



export { getListNoti, markRead, readOneNoti }