import { GetCharacterRequestParams, GetPositionRequestParams, ListResponse } from 'models/common';
import { Background, Character, WeaponPosition } from 'stores/master/type';
import { API } from 'utils/api/api-route';
import ApiUtils from 'utils/api/api.utils';

const MasterServices = {
  getCharacters: async (params: GetCharacterRequestParams) => {
    const res: ListResponse<Character> = await ApiUtils.fetch(API.getCharacter, params);
    return res;
  },
  getParts: async (params: GetPositionRequestParams) => {
    const res: ListResponse<WeaponPosition> = await ApiUtils.fetch(API.getPart, params);
    return res;
  },
  getBackgrounds: async () => {
    const res: ListResponse<Background> = await ApiUtils.fetch(API.getBackground);

    return res;
  },
  submitTerm: async () => {
    const res: any = await ApiUtils.post(API.acceptTerm);
    return res;
  },
  verifyWallet: async () => {
    const res: any = await ApiUtils.fetch(API.verifyUser);
    return res;
  },
};

export default MasterServices;
