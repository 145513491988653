import React, { useEffect } from 'react';
import IconNotFound from 'assets/images/icons/not-found-nft.svg';
import './styles.scss';
import { FILTER_MY_PAGE } from 'features/my-page';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes/routes';
import { BaseBuySingleNft } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchCharacter } from 'stores/master/actions';
import { AppDispatch } from 'stores/store';
import { useAppSelector } from 'stores/hooks';
import { getCharactersTransfromForSelect } from 'stores/master/selector';
import { NAME_NFTS } from 'constants/common';

interface IProps {
  isHaveButton?: boolean;
  isBorder?: boolean;
  typeButton?: string;
  title?: string;
  filterName?: string;
}

const NotNft = ({ isHaveButton, isBorder, typeButton, title, filterName }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const characterShylphy = useAppSelector(getCharactersTransfromForSelect).find(
    (item) => item.label === NAME_NFTS.Shylphy
  );

  useEffect(() => {
    dispatch(fetchCharacter());
  }, [dispatch]);
  const onClickBtn = (path: string) => {
    navigate(path);
  };

  const renderTitle = () => {
    if (filterName) {
      return t('notListNft.titleNotAll');
    }
    switch (typeButton) {
      case FILTER_MY_PAGE.ALL:
        return t('notListNft.titleNotAll');
      case FILTER_MY_PAGE.TRADEDNFT:
        return t('notListNft.titleNotTrade');
      case FILTER_MY_PAGE.FAVORITE:
        return t('notListNft.titleNotFavorite');
      default:
        return t('notListNft.titleNotDefault');
    }
  };
  const renderButton = () => {
    switch (typeButton) {
      case FILTER_MY_PAGE.FIRST_NFT:
        return;
      case FILTER_MY_PAGE.BODY:
        return (
          <button onClick={() => onClickBtn(`${BaseBuySingleNft}/body`)} className="btn">
            {t('notListNft.btnNotDefault')}
          </button>
        );
      case FILTER_MY_PAGE.PRESET:
        return (
          <button onClick={() => onClickBtn(`${BaseBuySingleNft}/preset`)} className="btn">
            {t('notListNft.btnNotDefault')}
          </button>
        );
      case FILTER_MY_PAGE.WEAPON:
        return (
          <button onClick={() => onClickBtn(`${BaseBuySingleNft}/weapon`)} className="btn">
            {t('notListNft.btnNotDefault')}
          </button>
        );
      case FILTER_MY_PAGE.NFT_2D:
        return (
          <button onClick={() => onClickBtn(`/buy-nft/illustration`)} className="btn">
            {t('notListNft.btnNotDefault')}
          </button>
        );
      case FILTER_MY_PAGE.SECOND_NFT:
        return characterShylphy?.value ? (
          <button
            onClick={() => onClickBtn(`/buy-nft/customize/${characterShylphy?.value}/1`)}
            className="btn"
          >
            {t('notListNft.btnNot2ndNft')}
          </button>
        ) : (
          <button onClick={() => onClickBtn(`${BaseBuySingleNft}/body`)} className="btn">
            {t('notListNft.btnNotDefault')}
          </button>
        );
      case FILTER_MY_PAGE.TRADEDNFT:
        return (
          <button onClick={() => onClickBtn(paths.home)} className="btn">
            {t('notListNft.btnNotTradeNft')}
          </button>
        );
      case FILTER_MY_PAGE.FAVORITE:
        return (
          <button onClick={() => onClickBtn(paths.nft2ndTop)} className="btn">
            {t('notListNft.btnNotFavorite')}
          </button>
        );
      default:
        return (
          <button onClick={() => onClickBtn(paths.home)} className="btn">
            {t('notListNft.btnNotDefault')}
          </button>
        );
    }
  };
  return (
    <div className={`${isBorder && 'containerNotNftBorder'} containerNotNft`}>
      <img src={IconNotFound} alt="" />
      <div className="title">{title || renderTitle()}</div>
      {isHaveButton && !filterName && renderButton()}
    </div>
  );
};

export default NotNft;
