/* eslint-disable no-empty */
export enum KeyStorage {
  LOCALE = 'i18nextLng',
  ACCESS_TOKEN = 'access_token',
  USER_INFO = 'user_info',
  UPBOND_TOKEN = 'upbond_token',
  UPBOND_TOKEN_EXPIRE = 'upbond_token_expire',
  AUTH_DATA = 'authData',
  MESSAGE = 'message',
  COLAPSED = 'colapsed',
  THEME = 'theme',
  I18n = 'i18nextLng',
  CONTRACT = 'CONTRACT'
}

const localStorageHelper = {
  set: (key: KeyStorage, value: string, store: Storage = localStorage): boolean => {
    try {
      store.setItem(key, value);
      return true;
    } catch (error) { }
    return false;
  },
  setObject: (key: KeyStorage, value: unknown, store: Storage = localStorage): boolean => {
    try {
      const newValue = JSON.stringify(value);
      store.setItem(key, newValue);
      return true;
    } catch (error) { }
    return false;
  },
  get: (key: KeyStorage, store: Storage = localStorage): any => {
    try {
      const value = store.getItem(key);
      if (value) {
        return value;
      }
    } catch (error) { }
  },

  getObject: (key: KeyStorage, store: Storage = localStorage): any => {
    try {
      const value = store.getItem(key);
      if (value) {
        const object = JSON.parse(value);
        return object;
      }
    } catch (error) { }
  },

  remove: (key: KeyStorage, store: Storage = localStorage) => {
    store.removeItem(key);
  },

  clear: (store: Storage = localStorage) => {
    store.clear();
  },
};

export default localStorageHelper;
